import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Segment, Image, Item, Header, Button, Icon, Grid } from 'semantic-ui-react';
import { toast } from 'react-toastify';
import {
  updateOrderCaddyStatus,
  updateOrderPaymentStatus,
} from '../../../app/firestore/firestoreService';
import { format, compareAsc } from 'date-fns';
import ru from 'date-fns/locale/ru';
import { useDispatch } from 'react-redux';
import { openModal } from '../../../app/common/modals/modalReducer';

const eventImageStyle = {
  filter: 'brightness(50%)',
};

const eventImageTextStyle = {
  position: 'absolute',
  bottom: '5%',
  left: '5%',
  width: '100%',
  height: 'auto',
  color: 'white',
};

const OrderDetailedHeader = ({
  order,
  currentUserProfile,
  isCreator,
  status,
  isModer,
  recruitmentFinish,
  caddyPrice,
}) => {
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  const orderTimeFilter = compareAsc(order?.date, new Date()) === 1;

  const handleCaddyRespond = async (respond, customPrice = false) => {
    setLoading(true);
    const isNew = !status;
    try {
      await updateOrderCaddyStatus(order, currentUserProfile, customPrice, respond, isNew);
    } catch (error) {
      toast.error(error.message);
    } finally {
      setLoading(false);
    }
  };

  const handlePaymentConfirm = async (action) => {
    setLoading(true);
    let status;

    if (action === 'paymentReceived') {
      status = 'paid';
    } else if (action === 'paymentSent') {
      status = 'pending';
    } else {
      return;
    }

    try {
      await updateOrderPaymentStatus(order, status);
    } catch (error) {
      toast.error(error.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Segment.Group>
      <Segment basic attached='top' style={{ padding: '0' }}>
        <Image src={`/assets/categoryImages/caddy.png`} fluid style={eventImageStyle} />

        <Segment basic style={eventImageTextStyle}>
          <Item.Group>
            <Item>
              <Item.Content>
                <Header size='huge' content={order.title} style={{ color: 'white' }} />
                <p>{format(order.date, 'd MMMM H:mm', { locale: ru })}</p>
                <p>
                  Заявка от{' '}
                  <strong>
                    <Link to={`/profile/${order.creatorUid}`}>{order.creator}</Link>
                  </strong>
                </p>
              </Item.Content>
            </Item>
          </Item.Group>
        </Segment>
      </Segment>

      <Segment attached='bottom' textAlign='center' clearing>
        {!isCreator && !recruitmentFinish && orderTimeFilter && !order?.isCancelled && (
          <>
            {status !== 'responded' && status !== 'approved' && (
              <>
                <Button
                  loading={loading}
                  onClick={() => handleCaddyRespond('responded')}
                  color='green'
                  className='button-margin'
                >
                  Откликнуться
                </Button>
                <Button
                  loading={loading}
                  onClick={() =>
                    dispatch(
                      openModal({
                        modalType: 'OrderDetailedCaddyRespondModal',
                        modalProps: {
                          loading,
                          handleCaddyRespond,
                        },
                      })
                    )
                  }
                  color='teal'
                  className='button-margin'
                >
                  Откликнуться с ₽
                </Button>
              </>
            )}

            {status !== 'declined' && status !== 'approved' && (
              <Button
                loading={loading}
                className='button-margin'
                onClick={() => handleCaddyRespond('declined')}
              >
                Отказаться
              </Button>
            )}
          </>
        )}
        {status === 'approved' && (
          <Grid verticalAlign='middle'>
            <Grid.Column width={1}>
              <Icon size='large' color='yellow' name='winner' />
            </Grid.Column>
            <Grid.Column width={14} textAlign='left' className='left-space'>
              <span>Поздравляем! Вас выбрали как кедди!</span>
            </Grid.Column>
          </Grid>
        )}

        {(isCreator || isModer) && (
          <>
            {recruitmentFinish && order.paymentStatus === 'unpaid' && (
              <>
                <Button
                  loading={loading}
                  className='button-margin'
                  onClick={() =>
                    dispatch(
                      openModal({
                        modalType: 'OrderDetailedPaymentModal',
                        modalProps: {
                          loading,
                          handlePaymentConfirm,
                          caddyPrice,
                        },
                      })
                    )
                  }
                  color='green'
                  // floated='right'
                  style={{ margin: 10 }}
                  // content='Оплатить'
                >
                  <Icon name='payment' className='caddy-choice' />
                  Оплатить бронирование
                </Button>
              </>
            )}

            {recruitmentFinish && isCreator && order.paymentStatus === 'pending' && (
              <Grid verticalAlign='middle'>
                <Grid.Column width={1}>
                  <Icon size='large' color='teal' name='wait' />
                </Grid.Column>
                <Grid.Column width={10} textAlign='left' className='left-space'>
                  <span>Ваш платеж обрабатывается...</span>
                </Grid.Column>
              </Grid>
            )}

            {recruitmentFinish && isModer && order.paymentStatus === 'pending' && (
              <Button
                loading={loading}
                className='button-margin'
                onClick={() => handlePaymentConfirm('paymentReceived')}
                color='teal'
                // floated='right'
                style={{ margin: 10 }}
                // content='Подтвердить оплату'
              >
                <Icon name='check' className='caddy-choice' />
                Подтвердить получение оплаты
              </Button>
            )}

            {recruitmentFinish && isCreator && order.paymentStatus === 'paid' && (
              <Grid verticalAlign='middle'>
                <Grid.Row>
                  <Grid.Column width={1}>
                    <Icon size='large' color='green' name='check circle' />
                  </Grid.Column>
                  <Grid.Column width={14} textAlign='left' className='left-space'>
                    <span>Бронирование оплачено и подтверждено.</span>
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                  <Grid.Column width={1}>
                    <Icon size='large' color='green' name='phone' />
                  </Grid.Column>
                  <Grid.Column width={14} textAlign='left' className='left-space'>
                    <span>
                      В случае изменения времени или места встречи, сообщите об этом, пожалуйста,
                      Вашему кедди. Телефон кедди доступен в карточке ниже.
                    </span>
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            )}

            {!recruitmentFinish && (
              <Button
                as={Link}
                to={`/manage/${order.id}`}
                color='orange'
                floated='right'
                className='button-margin'
              >
                Редактировать
              </Button>
            )}
          </>
        )}
      </Segment>
    </Segment.Group>
  );
};

export default OrderDetailedHeader;

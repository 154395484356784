import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { Divider, Grid, Image, List, Segment } from 'semantic-ui-react';
import { getCaddysProfiles } from '../../app/firestore/firestoreService';
import useFirestoreCollection from '../../app/hooks/useFirestoreCollection';
import { listenToCaddysProfiles } from '../profiles/profileActions';

const CaddyListPage = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  // let caddys = [];
  const { caddys, currentUserProfile } = useSelector((state) => state.profile);
  const { isModer } = currentUserProfile || false;

  // console.log(caddys.length);

  useFirestoreCollection({
    query: () => getCaddysProfiles(),
    data: (caddys) => dispatch(listenToCaddysProfiles(caddys)),
    deps: [dispatch],
  });

  if (!isModer) history.push('/orders');

  return (
    <Grid.Column>
      <Grid.Column>
        <Segment.Group>
          <Segment>Всего кедди: {caddys.length}</Segment>
          <Segment>
            <Grid columns={5} divided>
              <Grid.Column>Фото</Grid.Column>
              <Grid.Column>Имя</Grid.Column>
              <Grid.Column>ГКП</Grid.Column>
              <Grid.Column>Уровень</Grid.Column>
              <Grid.Column>Статус</Grid.Column>

              {/* <Grid.Column>{caddy.caddyStatus}</Grid.Column> */}
            </Grid>
          </Segment>
          {isModer &&
            caddys.length !== 0 &&
            caddys
              .sort(function (a, b) {
                var aname = a.displayName.toLowerCase(),
                  bname = b.displayName.toLowerCase();
                if (aname < bname) return -1;
                else return 1;
              })
              .map((caddy) => (
                <>
                  <Segment>
                    <Grid columns={5} divided>
                      <Grid.Row>
                        <Grid.Column>
                          <List.Item as={Link} to={`/profile/${caddy.id}`}>
                            <Image size='tiny' src={caddy.photoURL || '/assets/user.png'} />
                          </List.Item>
                        </Grid.Column>
                        <Grid.Column>
                          <List.Item as={Link} to={`/profile/${caddy.id}`}>
                            {caddy.displayName}
                          </List.Item>
                        </Grid.Column>
                        <Grid.Column>{caddy.hcp}</Grid.Column>
                        {/* <Grid.Column>{caddy.caddyStatus}</Grid.Column> */}
                        <Grid.Column>{caddy.caddyLevel}</Grid.Column>
                        <Grid.Column>{caddy.caddyStatus}</Grid.Column>
                      </Grid.Row>
                    </Grid>
                  </Segment>
                </>

                // <Item.Group>
                //   <Item>
                //     <Item.Image size='tiny' circular className='caddy-list' src={caddy.photoURL} />
                //     <Item.Header>{caddy.displayName}</Item.Header>
                //     <Item.Meta>Description</Item.Meta>
                //     <Item.Description>lalala</Item.Description>
                //     <Item.Extra>Additional Details</Item.Extra>
                //   </Item>
                // </Item.Group>
                // <OrderListItem order={order} key={caddy.id} />
              ))}

          <Divider />
        </Segment.Group>
      </Grid.Column>
    </Grid.Column>
  );
};

export default CaddyListPage;

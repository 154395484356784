import React from 'react';
import { Link } from 'react-router-dom';
import { Label, Button, Icon, Item, List, Segment } from 'semantic-ui-react';
import OrderListCaddy from './OrderListCaddy';
import { format } from 'date-fns';
import ru from 'date-fns/locale/ru';
import { golfClubs } from '../../../app/api/orderOptions';

const OrderListItem = ({ order }) => {
  const currentGolfClub = order?.golfСourse
    ? golfClubs.find((elem) => elem.key === order.golfСourse)?.text
    : false;

  return (
    <h1>
      <Segment.Group>
        <Segment>
          {/* <Item.Group>
            <Item> */}
          {/* <Item.Image size='tiny' src={order.creatorPhotoURL} />
              <Item.Content> */}

          {/* <Item.Description>
                  <Link to={`/profile/${order.creatorUid}`}>
                    <Label image>
                      <img src={order.creatorPhotoURL} alt='{order.creator}' />
                      {order.creator}
                    </Label>
                  </Link>
                </Item.Description> */}

          {(order.paymentStatus === 'paid' || order.paymentStatus === 'pending') &&
            !order.isCancelled && (
              <Label style={{ top: '-22px' }} ribbon='right' color='green' content='Кедди выбран' />
            )}

          {order.isCancelled && (
            <Label style={{ top: '-22px' }} ribbon='right' color='red' content='Заявка отменена' />
          )}

          <Item.Content
            style={
              order.paymentStatus === 'paid' ||
              order.paymentStatus === 'pending' ||
              order.isCancelled
                ? {
                    marginTop: -25,
                    marginBottom: 10,
                  }
                : {}
            }
          >
            Заявка на кедди в {currentGolfClub}
          </Item.Content>

          {/* <Divider /> */}
          {/* </Item.Content> */}
          {/* </Item>
          </Item.Group> */}
        </Segment>

        <Segment>
          <Item.Description>
            <Icon name='calendar' />{' '}
            {format(order.date, 'd MMMM, eeee, H:mm ', {
              locale: ru,
            })}
          </Item.Description>
          <Item.Description>
            <Icon name='marker' /> Место встречи: {order.meetingPoint}
          </Item.Description>

          <Item.Description>
            <span>
              <Icon name='user' />
              <Link to={`/profile/${order.creatorUid}`}>
                <Label image>
                  {order.creator}
                  <img
                    src={order.creatorPhotoURL || '/assets/user.png'}
                    alt={order.creator || ''}
                  />
                </Label>
              </Link>
            </span>
          </Item.Description>
        </Segment>
        {/* <Segment>
          <Label as='a' image>
            <img src={order.creatorPhotoURL} />
            {order.creator}
          </Label>
        </Segment> */}
        <Segment secondary>
          <List horizontal>
            {order.caddys &&
              order.caddys.map((caddy) => {
                const caddyProfile = order.caddysProfiles.find(
                  (profile) => profile.caddyId === caddy.caddyId
                );

                if (caddy.status === 'new' || caddy.status === 'declined') return null;

                return <OrderListCaddy key={caddyProfile?.caddyId} caddy={caddyProfile} />;
              })}
          </List>
        </Segment>
        <Segment textAlign='center' clearing>
          {/* <Button
            onClick={() => deleteOrderInFirestore(order.id)}
            color='red'
            floated='right'
            content='Удалить'
          /> */}
          <Button
            as={Link}
            to={`/orders/${order.id}`}
            color='teal'
            floated='right'
            content='Подробнее'
          />
        </Segment>
      </Segment.Group>
    </h1>
  );
};

export default OrderListItem;

import { toast } from 'react-toastify';
import firebase from '../config/firebase';
import { setUserProfileData } from './firestoreService';
import { parse } from 'date-fns';

export const firebaseObjectToArray = (snapshot) => {
  if (snapshot) return Object.entries(snapshot).map((e) => Object.assign({}, e[1], { id: e[0] }));
};

export const signInWithEmail = (creds) => {
  return firebase.auth().signInWithEmailAndPassword(creds.email, creds.password);
};

export const sendEmailSignInLink = (email, linkToExistUser = false) => {
  const domain = 'https://caddygo.ru/';
  const actionCodeSettings = {
    url: linkToExistUser ? domain + 'email-bind' : domain + 'email-login',
    handleCodeInApp: true,
    // iOS: {
    //   bundleId: 'com.example.ios'
    // },
    // android: {
    //   packageName: 'com.example.android',
    //   installApp: true,
    //   minimumVersion: '12'
    // },
    // dynamicLinkDomain: 'example.page.link'
  };

  firebase
    .auth()
    .sendSignInLinkToEmail(email, actionCodeSettings)
    .then((res) => {
      console.log(res);
      // The link was successfully sent. Inform the user.

      window.localStorage.setItem('emailForSignIn', email);
      // ...
    })
    .catch((error) => {
      throw error;
    });

  // console.log(email);
};

export const checkSignInEmailLink = (link) => {
  return firebase.auth().isSignInWithEmailLink(link);
};

export const signInWithEmailLink = async (email, link) => {
  const result = await firebase.auth().signInWithEmailLink(email, link);

  if (result.additionalUserInfo.isNewUser) {
    await result.user.updateProfile({
      displayName: email,
    });

    await setUserProfileData(result.user);
    result.isNewUser = true;
  }

  return result;
};

export const addSignInEmailLinkToExistUser = (email, link) => {
  const credential = firebase.auth.EmailAuthProvider.credentialWithLink(email, link);
  return firebase.auth().currentUser.linkWithCredential(credential);
};

export const signInWithPhoneNumber = async (creds) => {
  const recaptcha = new firebase.auth.RecaptchaVerifier('recaptcha', {
    size: 'invisible',
    callback: function (response) {
      console.log('Captcha Resolved');
    },
    defaultCountry: 'RU',
  });
  // const phoneNumber = `+${creds.phone}`;
  // console.log(phoneNumber);

  return firebase.auth().signInWithPhoneNumber(creds?.phone, recaptcha);
};

export const confirmSmsCode = async (response, code) => {
  try {
    return response.confirm(code);
    // console.log(res);
    // const credential = await firebase.auth.PhoneAuthProvider.credential(
    //   res.verificationId,
    //   code
    // );
    // console.log(credential);
  } catch (error) {
    throw error;
  }
};

export const signOutFirebase = () => {
  return firebase.auth().signOut();
};

export const registerInFirebase = async (response, creds) => {
  try {
    if (creds.firstName || creds.lastName) {
      await response.user.updateProfile({
        displayName: `${creds.firstName || 'Анонимный пользователь'} ${creds.lastName || ''}`,
      });
    }

    await firebase.auth().currentUser;

    response.user.firstName = creds.firstName || 'Анонимный пользователь';
    response.user.lastName = creds.lastName || '';
    response.user.gender = creds.gender || null;
    response.user.customEmail = creds.email || null;

    if (response.additionalUserInfo.providerId !== 'phone') {
      response.user.phone = creds.phone || null;
    }

    // console.log(response);

    return setUserProfileData(response.user);
  } catch (error) {
    throw error;
  }
};

export const socialLogin = async (selectedProvider, linkToExistUser = false) => {
  let provider;

  if (selectedProvider === 'facebook') {
    provider = new firebase.auth.FacebookAuthProvider();
    provider.addScope('public_profile, email, user_birthday, user_gender');
  } else if (selectedProvider === 'google') {
    provider = new firebase.auth.GoogleAuthProvider();
    provider.addScope('profile');
    provider.addScope('email');
    firebase.auth().useDeviceLanguage();
    // provider.addScope('public_profile, email, user_birthday, user_gender');
  }

  if (linkToExistUser) return provider;

  try {
    const result = await firebase.auth().signInWithPopup(provider);
    // console.log(result);

    if (selectedProvider === 'facebook') {
      result.user.firstName = result.additionalUserInfo.profile.first_name;
      result.user.lastName = result.additionalUserInfo.profile.last_name;
      result.user.customEmail = result.additionalUserInfo.profile.email;
      result.user.birthday = parse(
        result.additionalUserInfo.profile.birthday,
        'MM/dd/yyyy',
        new Date()
      );
      result.user.gender = result.additionalUserInfo.profile.gender;
      await result.user.updateEmail(result.additionalUserInfo.profile.email);
    } else if (selectedProvider === 'google') {
      result.user.firstName = result.additionalUserInfo.profile.name;
      result.user.customEmail = result.additionalUserInfo.profile.email;
      await result.user.updateEmail(result.additionalUserInfo.profile.email);
      // result.user.lastName = result.additionalUserInfo.profile.last_name;
      // result.user.gender = result.additionalUserInfo.profile.gender;
    }

    // console.log(result);

    if (result.additionalUserInfo.isNewUser) {
      await result.user.updateProfile({
        displayName: result.user.firstName
          ? `${result.user.firstName || 'Анонимный пользователь'} ${result.user.lastName || ''}`
          : result.user.email || result.user.customEmail,
      });
      await setUserProfileData(result.user);
      result.isNewUser = true;
    }

    return result;
  } catch (error) {
    // console.log(error);
    toast.error(error.message);
  }
};

export const uploadToFirebaseStorage = (file, filename) => {
  const user = firebase.auth().currentUser;
  const storageRef = firebase.storage().ref();
  return storageRef.child(`${user.uid}/user_images/${filename}`).put(file);
};

export const deleteFromFirebaseStorage = (filename) => {
  const userUid = firebase.auth().currentUser.uid;
  const storageRef = firebase.storage().ref();
  const photoRef = storageRef.child(`${userUid}/user_images/${filename}`);
  return photoRef.delete();
};

export const getUserNotificationsRef = () => {
  const user = firebase.auth().currentUser;
  if (!user) return null;

  return firebase.database().ref(`notifications/${user.uid}`).orderByKey().limitToLast(30);
};

export const markAsReadUserNotifications = (notificationsRef) => {
  const user = firebase.auth().currentUser;
  const notifications = notificationsRef.val();
  for (let notificationKey in notifications) {
    firebase
      .database()
      .ref(`notifications/${user.uid}/${notificationKey}`)
      .update({ status: 'read' });
  }
};

export const markAsReadAllUserNotification = async () => {
  const user = firebase.auth().currentUser;
  const snapshot = await firebase
    .database()
    .ref(`notifications/${user.uid}`)
    .orderByChild('status')
    .equalTo('unread')
    .once('value');

  if (!snapshot.exists()) return;
  const notifications = snapshot.val();

  for (let notificationKey in notifications) {
    firebase
      .database()
      .ref(`notifications/${user.uid}/${notificationKey}`)
      .update({ status: 'read' });
  }
};

export const getUserUnreadNotificationsCountRef = () => {
  const user = firebase.auth().currentUser;
  if (!user) return null;

  return firebase
    .database()
    .ref(`notifications/${user.uid}`)
    .orderByChild('status')
    .equalTo('unread')
    .limitToLast(100);
};

export const addOrderComment = (orderId, values) => {
  const user = firebase.auth().currentUser;
  const newComment = {
    displayName: user.displayName,
    photoURL: user.photoURL,
    uid: user.uid,
    text: values.comment,
    parentId: values.parentId,
    date: Date.now(),
  };

  return firebase.database().ref(`comments/${orderId}`).push(newComment);
};

export const getOrderCommentsRef = (orderId) => {
  return firebase.database().ref(`comments/${orderId}`).orderByKey();
};
export const deleteOrderComment = (orderId, commentId) => {
  return firebase.database().ref(`comments/${orderId}/${commentId}`).remove();
};

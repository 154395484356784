import { Form, Formik } from 'formik';
import React, { useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import * as Yup from 'yup';
import StyledPhoneInput from '../../app/common/form/StyledPhoneInput';
import { Button, Container, Divider, Label, List, Segment } from 'semantic-ui-react';
import {
  confirmSmsCode,
  registerInFirebase,
  signInWithPhoneNumber,
} from '../../app/firestore/firebaseService';
import SocialLogin from './SocialLogin';
import TextInput from '../../app/common/form/TextInput';
import { useSelector } from 'react-redux';
import { phoneRegExp } from '../../app/common/util/util';

const LoginForm = () => {
  const history = useHistory();
  const [codeConfirming, setCodeConfirming] = useState(false);
  const [serverResponse, setServerResponse] = useState(null);
  const [creds, setCreds] = useState(null);

  const { authenticated } = useSelector((state) => state.auth);
  if (authenticated && !serverResponse) history.push('/orders');

  return (
    <Segment textAlign='center' vertical>
      <Container style={{ width: '350px' }}>
        <Formik
          key='main'
          initialValues={{ phone: '', firstName: '', lastName: '' }}
          validationSchema={Yup.object({
            phone: Yup.string()
              .matches(phoneRegExp, 'Проверьте корректность введённого номера')
              .required('Необходимо указать номер телефона'),
          })}
          onSubmit={async (values, { setSubmitting, setErrors }) => {
            try {
              setCodeConfirming(true);
              setCreds(values);
              const response = await signInWithPhoneNumber(values);
              // console.log(response);
              setServerResponse(response);
              setSubmitting(false);
            } catch (error) {
              console.log(error);
              setErrors({
                auth: 'Ошибка авторизации. Проверьте, корректно ли указаны данные',
              });
              setSubmitting(false);
            }
          }}
        >
          {({ isSubmitting, isValid, dirty, errors }) => {
            return (
              <>
                <Form className='ui form'>
                  {/* {registration && (
                    <>
                      <TextInput name='firstName' placeholder='Имя' />
                      <TextInput name='lastName' placeholder='Фамилия' />
                    </>
                  )} */}

                  <StyledPhoneInput
                    name='phone'
                    placeholder='+7 (912) 345-67-89'
                    disabled={isSubmitting}
                    style={{ paddingLeft: 48 }}
                  />

                  {errors.auth && (
                    <Label basic color='red' style={{ marginBottom: 10 }} content={errors.auth} />
                  )}
                  {!codeConfirming && (
                    <Button
                      loading={isSubmitting}
                      disabled={!isValid || !dirty || isSubmitting}
                      type='submit'
                      fluid
                      size='large'
                      color='teal'
                      content='Войти по телефону'
                    />
                  )}
                  <div id='recaptcha'></div>
                  {!codeConfirming && <Divider horizontal>Или</Divider>}
                  {!codeConfirming && <SocialLogin />}
                </Form>
              </>
            );
          }}
        </Formik>

        <Formik
          key='confirm'
          initialValues={{ code: '' }}
          validationSchema={Yup.object({
            code: Yup.string().matches(/\b\d{6}\b/, 'Проверьте корректность введённого кода'),
          })}
          onSubmit={async (values, { setSubmitting, setErrors }) => {
            try {
              const res = await confirmSmsCode(serverResponse, values.code);
              // console.log(res);

              if (res.additionalUserInfo.isNewUser) {
                await registerInFirebase(res, creds);

                setSubmitting(false);
                setCodeConfirming(false);
                history.push({
                  pathname: '/reg',
                  userRes: res,
                });
              } else {
                history.push('/orders');
              }
            } catch (error) {
              console.log(error);
              setErrors({
                auth: 'Ошибка авторизации. Пожалуйста, проверьте, корректно ли введен код',
              });
              setSubmitting(false);
              setCodeConfirming(false);
            }
          }}
        >
          {({ isSubmitting, isValid, dirty, errors }) => {
            return (
              <Form className='ui form'>
                {codeConfirming && (
                  <>
                    <span>Введите код подтверждения из смс:</span>
                    <TextInput
                      name='code'
                      placeholder='123456'
                      type='tel'
                      inputMode='tel'
                      autoCorrect='off'
                      // autoComplete='off'
                      autocomplete='one-time-code'
                      maxLength='6'
                      disabled={isSubmitting}
                      style={{ marginTop: 10 }}
                    />
                  </>
                )}

                {errors.auth && (
                  <Label basic color='red' style={{ marginBottom: 10 }} content={errors.auth} />
                )}
                {codeConfirming && (
                  <Button
                    loading={isSubmitting}
                    disabled={!isValid || !dirty || isSubmitting}
                    type='submit'
                    fluid
                    size='large'
                    color='teal'
                    content='Отправить'
                  />
                )}
              </Form>
            );
          }}
        </Formik>

        <List relaxed style={{ marginTop: 20, color: 'gray', fontSize: '0.8em' }}>
          <List.Item>
            Нажимая кнопку Войти, вы принимаете{' '}
            <Link to='/docs/terms.html' target='_blank'>
              пользовательское соглашение
            </Link>{' '}
            и{' '}
            <Link to='/docs/privacy.html' target='_blank'>
              политику конфиденциальности
            </Link>
            .
          </List.Item>
        </List>

        {/* {registration && !codeConfirming && (
          <List relaxed style={{ margin: 30, fontSize: '1.1em' }}>
            <List.Item>Уже зарегистрированы?</List.Item>
            <List.Item>
              <Link to='/login'>Войти</Link>
            </List.Item>
          </List>
        )} */}
      </Container>
    </Segment>
  );
};

export default LoginForm;

import React from 'react';
import { useField, useFormikContext } from 'formik';
import { Checkbox, FormField, Label } from 'semantic-ui-react';

const CheckboxInput = ({ ...props }) => {
  const [field, meta] = useField(props);
  const { setFieldValue } = useFormikContext();
  // console.log(field);

  return (
    <FormField error={meta.touched && !!meta.error}>
      <Checkbox
        checked={field.value || false}
        {...props}
        onChange={(e, { name, checked }) => {
          setFieldValue(name, !!checked);
        }}
      />
      {meta.touched && meta.error ? (
        <Label basic color='red'>
          {meta.error}
        </Label>
      ) : null}
    </FormField>
  );
};

export default CheckboxInput;

import React, { useState } from 'react';
import { Card, Grid, Image, Tab } from 'semantic-ui-react';
import { format } from 'date-fns';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useWindowResize } from 'react-resize-tracker';
import useFirestoreCollection from '../../../app/hooks/useFirestoreCollection';
import { getUserOrdersQuery } from '../../../app/firestore/firestoreService';
import { listenToUserOrders } from '../profileActions';

const OrdersTab = ({ profile }) => {
  const [currentWidth] = useWindowResize();
  const dispatch = useDispatch();
  const [activeTab, setActiveTab] = useState(0);
  const { profileOrders } = useSelector((state) => state.profile);
  const { loading } = useSelector((state) => state.async);

  useFirestoreCollection({
    query: () => getUserOrdersQuery(activeTab, profile.id),
    data: (orders) => dispatch(listenToUserOrders(orders)),
    deps: [dispatch, activeTab, profile.id],
  });

  const panes = [
    { menuItem: 'Созданные', pane: { key: 'createdOrders' } },
    { menuItem: 'Выбрали как кедди', pane: { key: 'finishedOrders' } },
    { menuItem: 'Не выбрали как кедди', pane: { key: 'respondedOrders' } },
    { menuItem: 'Отклоненные', pane: { key: 'declinedOrders' } },
  ];

  return (
    <Tab.Pane loading={loading}>
      <Grid>
        {/* <Grid.Column widths={4}>
          <Header floated='left' icon='calendar' content='Бронирования' />
        </Grid.Column> */}
        <Grid.Column>
          <Tab
            onTabChange={(event, data) => setActiveTab(data.activeIndex)}
            panes={panes}
            menu={{
              secondary: true,
              // pointing: true,
              fluid: true,
              style: { justifyContent: 'center' },
              vertical: currentWidth > 700 ? false : true,
            }}
            grid={{ tabWidth: currentWidth > 700 ? '' : 16 }}
          />
          <Card.Group
            // itemsPerRow={5}
            style={{ marginTop: 10, justifyContent: 'center' }}
          >
            {profileOrders.map((order) => (
              <Card as={Link} to={`/orders/${order.id}`} key={order.id}>
                <Image
                  src='/assets/categoryImages/caddy.png'
                  style={{ minHeigth: 100, objectFit: 'cover' }}
                />
                <Card.Content>
                  {/* <Card.Header content='Title' textAlign='center' /> */}
                  <Card.Header textAlign='center'>
                    <div>{format(order.date, 'dd MMM yyyy')}</div>
                    <div>{format(order.date, 'hh:mm a')}</div>
                  </Card.Header>
                </Card.Content>
              </Card>
            ))}
          </Card.Group>
        </Grid.Column>
      </Grid>
    </Tab.Pane>
  );
};

export default OrdersTab;

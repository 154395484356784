import { Form, Formik } from 'formik';
import React, { useEffect } from 'react';
import TextInput from '../../../app/common/form/TextInput';
import TextArea from '../../../app/common/form/TextArea';
import { Button } from 'semantic-ui-react';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import { updateUserProfile } from '../../../app/firestore/firestoreService';
import DateInput from '../../../app/common/form/DateInput';
import { addYears } from 'date-fns';
import SelectInput from '../../../app/common/form/SelectInput';
import { golfClubs } from '../../../app/api/orderOptions';
import { phoneRegExp } from '../../../app/common/util/util';
import StyledPhoneInput from '../../../app/common/form/StyledPhoneInput';
import CheckboxInput from '../../../app/common/form/CheckboxInput';

const ProfileForm = ({ profile, setEditMode }) => {
  const caddyParams = profile.isCaddy
    ? {
        caddySince: Yup.string().required('Поле обязательно для заполнения'),
        achievements: Yup.string().required('Поле обязательно для заполнения'),
        driversLicense: Yup.boolean(),
      }
    : {};

  useEffect(() => {
    const datePickers = document.getElementsByClassName('react-datepicker__input-container');

    for (let i = 0; i < datePickers.length; i++) {
      datePickers[i].childNodes[0].setAttribute('inputMode', 'none');
    }
  }, []);

  // console.log(profile);

  return (
    <Formik
      initialValues={{
        firstName: profile.firstName,
        middleName: profile.middleName || '',
        lastName: profile.lastName || '',
        gender: profile.gender || '',
        birthday: profile.birthday || '',
        hcp: profile.hcp || '',
        homeCity: profile.homeCity || '',
        homeClub: profile.homeClub || '',
        caddySince: profile.caddySince || '',
        achievements: profile.achievements || '',
        phoneNumber: profile.phoneNumber || '',
        email: profile.email || '',
        driversLicense: profile.driversLicense || '',
      }}
      validationSchema={Yup.object({
        ...caddyParams,
        firstName: Yup.string().required('Имя обязательно для заполнения'),
        email: Yup.string().email(),
        phoneNumber: Yup.string()
          .matches(phoneRegExp, 'Проверьте корректность введённого номера')
          .required('Необходимо указать номер телефона'),
      })}
      onSubmit={async (values, { setSubmitting }) => {
        try {
          await updateUserProfile(values);
        } catch (error) {
          toast.error(error.message);
        } finally {
          setSubmitting(false);
          setEditMode(false);
        }
        console.log(values);
      }}
    >
      {({ isSubmitting, isValid, dirty }) => (
        <Form className='ui form'>
          <TextInput name='firstName' placeholder='Имя' />
          <TextInput name='middleName' placeholder='Отчество' />
          <TextInput name='lastName' placeholder='Фамилия' />

          <SelectInput
            name='gender'
            placeholder='Пол'
            options={[
              { key: 'male', text: 'Мужской', value: 'male' },
              { key: 'female', text: 'Женский', value: 'female' },
            ]}
          />

          <DateInput
            name='birthday'
            placeholderText='День рождения'
            dateFormat='d MMMM yyyy'
            showMonthDropdown
            showYearDropdown
            maxDate={addYears(new Date(), 0)}
            locale='ru'
          />

          <StyledPhoneInput
            name='phoneNumber'
            placeholder='+7 (912) 345-67-89'
            initVal={profile.phoneNumber}
            style={{ paddingLeft: 48 }}
          />

          <TextInput name='email' placeholder='Email' />

          {profile.isCaddy && (
            <DateInput
              name='caddySince'
              required
              placeholderText='Начало карьеры кедди'
              showYearPicker
              yearItemNumber={9}
              maxDate={addYears(new Date(), 0)}
              dateFormat='yyyy'
              locale='ru'
            />
          )}

          <TextInput name='hcp' placeholder='Гандикап' />

          <TextInput name='homeCity' placeholder='Город' />

          <SelectInput
            name='homeClub'
            placeholder='Выберете домашний клуб или начните вводить название'
            search
            options={golfClubs}
          />

          {profile.isCaddy && <TextArea name='achievements' placeholder='Достижения' required />}

          {profile.isCaddy && (
            <CheckboxInput
              name='driversLicense'
              label='Есть водительское удостоверение (могу быть трезвым водителем)'
              value={profile.driversLicense}
            />
          )}

          <Button
            loading={isSubmitting}
            disabled={isSubmitting || !isValid || !dirty}
            type='submit'
            size='large'
            positive
            content='Сохранить'
          />
        </Form>
      )}
    </Formik>
  );
};

export default ProfileForm;

import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { Button, Header, Icon, Segment } from 'semantic-ui-react';
import { openModal } from '../../../app/common/modals/modalReducer';
import { socialLogin } from '../../../app/firestore/firebaseService';

const SettingsPage = () => {
  const dispatch = useDispatch();
  const { currentUser } = useSelector((state) => state.auth);
  const [isConnected, setIsConnected] = useState({
    'google.com': currentUser.providerData.some((data) => data.providerId === 'google.com'),
    'facebook.com': currentUser.providerData.some((data) => data.providerId === 'facebook.com'),
    password: currentUser.providerData.some((data) => data.providerId === 'password'),
    phone: currentUser.providerData.some((data) => data.providerId === 'phone'),
  });
  // console.log(currentUser);

  const updateIsConnected = (providerId, value) => {
    setIsConnected((prev) => {
      return {
        ...prev,
        [providerId]: value,
      };
    });
  };

  const handleLinkSocialAuth = async (providerId) => {
    try {
      const provider = await socialLogin(providerId, true);

      await currentUser.linkWithPopup(provider);
      updateIsConnected(provider.providerId, true);
      toast.success(`${providerId} успешно привязан`);
    } catch (error) {
      toast.error(error.message);
    }
  };

  const unlinkAuthMethod = async (providerId) => {
    try {
      if (currentUser.providerData.length === 1) {
        toast.error('Нельзя отключить единственный метод входа');
      }

      await currentUser.unlink(providerId);
      updateIsConnected(providerId, false);
      toast.success(
        `Авторизация через ${providerId === 'password' ? 'Email' : providerId} успешно отвязана`
      );
    } catch (error) {
      toast.error(error.message);
    }
  };

  return (
    <>
      <Segment.Group>
        <Segment>
          <Header dividing content='Авторизация' />

          <Header color='teal' sub content='Текущие способы входа в аккаунт:' />

          {isConnected['phone'] && (
            <Button
              // icon='phone'
              color='green'
              content='SMS'
              className='button-margin-top'
            />
          )}

          {isConnected['facebook.com'] && (
            // <Label as='a' color='facebook'>
            //   Facebook
            //   <Icon name='delete' />
            // </Label>
            <Button
              icon
              labelPosition='right'
              color='facebook'
              className='button-margin-top'
              onClick={() => unlinkAuthMethod('facebook.com')}
            >
              Facebook
              <Icon name='delete' className='icon-social-button' />
            </Button>
          )}

          {isConnected['google.com'] && (
            <Button
              icon
              labelPosition='right'
              color='google plus'
              className='button-margin-top'
              onClick={() => unlinkAuthMethod('google.com')}
            >
              Google
              <Icon name='delete' className='icon-social-button' />
            </Button>
          )}

          {isConnected['password'] && (
            <Button
              icon
              labelPosition='right'
              color='linkedin'
              className='button-margin-top'
              onClick={() => unlinkAuthMethod('password')}
            >
              Вход по ссылке через Email
              <Icon name='delete' className='icon-social-button' />
            </Button>
          )}
          <br />
        </Segment>

        <Segment>
          <Header
            color='teal'
            sub
            className='button-margin-top'
            content='Для большего удобства и надежности Вы можете добавить дополниотельные способы входа в
          аккаунт:'
          />
          {/* <br /> */}
          {/* 
          {!isConnected['phone'] && (
            <Button
              icon='phone'
              color='green'
              content='Вход по SMS'
              className='button-margin-top'
            />
          )} */}

          {!isConnected['facebook.com'] && (
            <Button
              icon='facebook'
              color='facebook'
              content='Вход через Facebook'
              className='button-margin-top'
              onClick={() => handleLinkSocialAuth('facebook')}
            />
          )}

          {!isConnected['google.com'] && (
            <Button
              icon='google'
              color='google plus'
              content='Вход через Google'
              className='button-margin-top'
              onClick={() => handleLinkSocialAuth('google')}
            />
          )}

          {!isConnected['password'] && (
            <Button
              icon='mail'
              color='linkedin'
              content='Вход по ссылке через Email'
              className='button-margin-top'
              onClick={() =>
                dispatch(
                  openModal({
                    modalType: 'EmailLoginForm',
                    modalProps: {
                      linkToExistUser: true,
                    },
                  })
                )
              }
            />
          )}

          <br />
        </Segment>
      </Segment.Group>
      {/*       
      <Segment>
        <Header dividing content='Оповещения' />
        <><Header color='teal' sub content='Email' /></>
      </Segment> */}
    </>
  );
};

export default SettingsPage;

import React from 'react';
import { Formik, Form } from 'formik';
import { toast } from 'react-toastify';
import { addOrderComment } from '../../../app/firestore/firebaseService';
import { Button } from 'semantic-ui-react';
import * as Yup from 'yup';
import TextArea from '../../../app/common/form/TextArea';

const OrderDetailedCommentForm = ({ orderId, parentId, closeForm }) => {
  return (
    <Formik
      initialValues={{ comment: '' }}
      validationSchema={Yup.object({
        comment: Yup.string(),
        // .required('Пожалуйста, введите текст в этом поле для отправки'),
      })}
      onSubmit={async (values, { setSubmitting, resetForm }) => {
        try {
          await addOrderComment(orderId, { ...values, parentId });
          resetForm();
        } catch (error) {
          toast.error(error.message);
        } finally {
          setSubmitting(false);
          closeForm({ open: false, commentId: null });
        }
      }}
    >
      {({ isSubmitting, isValid, dirty }) => (
        <Form className='ui form button-margin-top'>
          <TextArea name='comment' placeholder='Ваш вопрос или комментарий' rows={2} />
          <div style={{ textAlign: 'right' }}>
            <Button
              loading={isSubmitting}
              // primary
              color='teal'
              type='submit'
              disabled={!isValid || !dirty || isSubmitting}
            >
              Отправить
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default OrderDetailedCommentForm;

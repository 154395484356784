import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Dropdown, Header, Menu, Segment } from 'semantic-ui-react';
import {
  setCaddyChoiceFilter,
  // setStartDate,
  setTimeFilter,
  setTypeFilter,
} from './orderActions';
// import Calendar from 'react-calendar';

const OrderFilters = ({ isCaddy, isModer, loading, mobile = false }) => {
  const dispatch = useDispatch();
  let {
    typeFilter,
    timeFilter,
    caddyChoiceFilter,
    // startDate
  } = useSelector((state) => state.order);

  const caddyRequests = isModer !== undefined ? 'isModer' : 'caddyRequests';
  // if (!typeFilter) typeFilter = caddyRequests;

  // const typeFilterDefaultValue = isCaddy ? 'caddyRequests' : 'isCreator';
  // if (!typeFilter) dispatch(setTypeFilter(typeFilterDefaultValue));
  // console.log(caddyRequests, typeFilter, isModer);

  const typeOptions = [
    // {
    //   key: 'all',
    //   text: 'Все бронирования кедди',
    //   value: 'all',
    //   content: 'Все бронирования кедди',
    //   onMouseDown: () => dispatch(setTypeFilter('all')),
    // },
    {
      key: 'isCreator',
      text: 'Мои бронирования кедди',
      value: 'isCreator',
      content: 'Мои бронирования кедди',
      onMouseDown: () => dispatch(setTypeFilter('isCreator')),
    },
    {
      key: caddyRequests,
      text: 'Бронирования кедди от гольфистов',
      value: caddyRequests,
      content: 'Бронирования кедди от гольфистов',
      onMouseDown: () => dispatch(setTypeFilter(caddyRequests)),
    },
  ];

  // const timeOptions = [
  //   {
  //     key: 'upcoming',
  //     text: 'предстоящие',
  //     value: 'upcoming',
  //     content: 'Предстоящие',
  //     onMouseDown: () => setPredicate('timeFilter', 'upcoming'),
  //   },
  //   {
  //     key: 'past',
  //     text: 'прошедшие',
  //     value: 'past',
  //     content: 'Прошедшие',
  //     onMouseDown: () => setPredicate('timeFilter', 'past'),
  //   },
  // ];

  const caddyChoiceOptions = [
    {
      key: 'all',
      text: 'Все',
      value: 'all',
      content: 'Все',
      onMouseDown: () => dispatch(setCaddyChoiceFilter('all')),
    },
    {
      key: 'new',
      text: 'Новые',
      value: 'new',
      content: 'Новые',
      onMouseDown: () => dispatch(setCaddyChoiceFilter('new')),
    },
    {
      key: 'approved',
      text: 'В которых я выбран как кедди',
      value: 'approved',
      content: 'В которых я выбран как кедди',
      onMouseDown: () => dispatch(setCaddyChoiceFilter('approved')),
    },
    {
      key: 'responded',
      text: 'Подтвержденные мной',
      value: 'responded',
      content: 'Подтвержденные мной',
      onMouseDown: () => dispatch(setCaddyChoiceFilter('responded')),
    },
    {
      key: 'declined',
      text: 'Отклоненные мной',
      value: 'declined',
      content: 'Отклонённые мной',
      onMouseDown: () => dispatch(setCaddyChoiceFilter('declined')),
    },
  ];

  return (
    <>
      {!mobile && <Header attached color='teal' icon='filter' content='Фильтр заявок' />}
      {typeFilter && (
        <Segment attached='bottom'>
          <Menu size='large' widths={2} attached='bottom'>
            <Menu.Item
              content='Предстоящие'
              active={timeFilter === 'upcoming'}
              onClick={() => dispatch(setTimeFilter('upcoming'))}
              disabled={loading}
            />
            <Menu.Item
              content='Прошедшие'
              active={timeFilter === 'past'}
              onClick={() => dispatch(setTimeFilter('past'))}
              disabled={loading}
            />
          </Menu>

          {isCaddy && (
            <>
              <Dropdown
                key='type'
                fluid
                selection
                options={typeOptions}
                defaultValue={typeOptions.find((e) => e.key === typeFilter)?.value}
                style={{ marginBottom: 10, marginTop: 10, padding: '1rem' }}
              />

              {typeFilter !== 'isCreator' && (
                <Dropdown
                  key='choice'
                  fluid
                  selection
                  options={caddyChoiceOptions}
                  defaultValue={caddyChoiceOptions.find((e) => e.key === caddyChoiceFilter)?.value}
                  style={{ padding: '1rem' }}
                />
              )}
            </>
          )}
        </Segment>
      )}
      {/* <Header icon='calendar' attached color='teal' content='Select date' />
      <Calendar
        onChange={(date) => dispatch(setStartDate(date)) }
        value={startDate || new Date()}
        tileDisabled={() => loading}
      /> */}
    </>
  );
};

export default OrderFilters;

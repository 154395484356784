import { useField } from 'formik';
import React from 'react';
import { FormField, Label, Select } from 'semantic-ui-react';

const MultiSelectInput = ({ label, ...props }) => {
  const [field, meta, helpers] = useField(props);
  // const defaultValues = props?.options?.map((e) => e.value);
  // if (!field.value || field.value.length === 0) field.value = defaultValues;

  return (
    <FormField error={meta.touched && !!meta.error}>
      <label>{label}</label>
      <Select
        clearable
        selection
        multiple
        value={field.value || null}
        onChange={(e, data) => {
          helpers.setValue(data.value);
        }}
        onBlur={() => helpers.setTouched(true)}
        {...props}
      />
      {meta.touched && meta.error ? (
        <Label basic color='red'>
          {meta.error}
        </Label>
      ) : null}
    </FormField>
  );
};

export default MultiSelectInput;

import React, { useState } from 'react';
import { Button, Grid, Icon, Tab } from 'semantic-ui-react';
import ProfileForm from './ProfileForm';
import { caddyGender, caddyLevel, golfClubs } from '../../../app/api/orderOptions';
import { format } from 'date-fns';
import ru from 'date-fns/locale/ru';
import PhoneNumber from '../../other/PhoneNumber';

const MainInfoTab = ({ profile, isCurrentUser, isModer }) => {
  const [editMode, setEditMode] = useState(false);

  const userGender = profile?.gender
    ? caddyGender.find((elem) => elem.key === profile.gender)
    : profile.gender;

  const currentCaddyLevel = profile?.caddyLevel
    ? caddyLevel.find((elem) => elem.key === profile.caddyLevel)?.text
    : profile.caddyLevel;

  const currentHomeClub = profile?.homeClub
    ? golfClubs.find((elem) => elem.key === profile.homeClub)?.text
    : profile.homeClub;

  return (
    <Tab.Pane width={16}>
      <Grid>
        <Grid.Column width={16}>
          {isCurrentUser && (
            <Button
              onClick={() => setEditMode(!editMode)}
              floated='right'
              basic
              content={editMode ? 'Отмена' : 'Редактировать'}
              icon='edit'
            />
          )}
        </Grid.Column>

        <Grid.Column width={16}>
          {editMode ? (
            <ProfileForm profile={profile} setEditMode={setEditMode} />
          ) : (
            <>
              <div style={{ fontSize: 'larger' }}>
                <>
                  <Grid.Row>
                    <Icon disabled name='user' />
                    {`${profile.firstName} ${profile.middleName || ''} ${profile.lastName || ''}`}
                  </Grid.Row>

                  {userGender && (
                    <Grid.Row>
                      <Icon disabled name={userGender.icon} />
                      {userGender?.text}
                    </Grid.Row>
                  )}

                  {profile.birthday && (
                    <Grid.Row>
                      <Icon disabled name='birthday' />
                      {/* День рождения:{' '} */}
                      {format(profile.birthday, 'dd MMMM yyyy', { locale: ru })}
                    </Grid.Row>
                  )}

                  {(isModer || isCurrentUser) && profile.phoneNumber && (
                    <Grid.Row>
                      <>
                        <Icon name='phone' disabled />
                        <PhoneNumber phoneNumber={profile.phoneNumber} />
                      </>
                      {/* <Icon disabled name='phone' />
                      {profile.phoneNumber}
                      <Icon name='whatsapp square' />
                      {profile.phoneNumber} */}
                    </Grid.Row>
                  )}

                  {(isModer || isCurrentUser) && profile.email && (
                    <Grid.Row>
                      <Icon disabled name='mail' />
                      {profile.email}
                    </Grid.Row>
                  )}

                  {profile.caddySince && (
                    <Grid.Row>
                      <Icon disabled name='calendar check' />
                      Кедди с {format(profile.caddySince, 'yyyy')} года
                    </Grid.Row>
                  )}

                  {currentCaddyLevel && (
                    <Grid.Row>
                      <Icon disabled name='chart line' />
                      Уровень кедди — {currentCaddyLevel}
                    </Grid.Row>
                  )}

                  {profile.hcp && (
                    <Grid.Row>
                      <Icon disabled name='lightning' />
                      {profile.hcp} гандикап
                    </Grid.Row>
                  )}

                  {profile.price && (
                    <Grid.Row>
                      <Icon disabled name='money bill alternate outline' />
                      {profile.price} ₽
                    </Grid.Row>
                  )}

                  {profile.homeCity && (
                    <Grid.Row>
                      <Icon disabled name='map marker alternate' />
                      {/* Город:  */}
                      {profile.homeCity}
                    </Grid.Row>
                  )}

                  {profile.homeClub && (
                    <Grid.Row>
                      <Icon disabled name='home' />
                      {/* Домашний клуб:  */}
                      {currentHomeClub || ''}
                    </Grid.Row>
                  )}

                  {profile.achievements && (
                    <Grid.Row>
                      <Icon disabled name='winner' />
                      {/* Достижения:  */}
                      {profile.achievements || ''}
                    </Grid.Row>
                  )}

                  {profile.driversLicense && (
                    <Grid.Row>
                      <Icon disabled name='vcard' />
                      Есть водительское удостоверение
                      {/* Права:  */}
                      {/* {profile.driversLicense || ''} */}
                    </Grid.Row>
                  )}
                </>
              </div>
            </>
          )}
        </Grid.Column>
      </Grid>
    </Tab.Pane>
  );
};

export default MainInfoTab;

import React from 'react';
import { useSelector } from 'react-redux';
import { Route } from 'react-router';
import LoginPage from '../../features/auth/LoginPage';

const PrivateRoute = ({ component: Component, prevLocation, ...rest }) => {
  const { authenticated } = useSelector((state) => state.auth);

  return (
    <Route
      {...rest}
      render={(props) =>
        authenticated ? <Component {...props} /> : <LoginPage {...props} />
      }
    />
  );
};

export default PrivateRoute;

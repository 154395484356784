import React from 'react';
import { Container, Header, Item, Segment } from 'semantic-ui-react';
import PhoneNumber from './PhoneNumber';

const FeedbackPage = () => {
  const salesPhoneNumber = process.env.REACT_APP_ADMIN_PHONE_NUMBER;
  const techPhoneNumber = process.env.REACT_APP_TECH_PHONE_NUMBER;

  return (
    <Container text>
      <Segment.Group>
        <Segment>
          {/* <Header>Обратная связь</Header> */}

          <Item>
            <Header as='h3' color='teal'>
              Обратная связь
            </Header>
            <Item.Content>
              <p>Мы готовы ответить на любые интересующие Вас вопросы!</p>
            </Item.Content>
          </Item>
        </Segment>
        <Segment>
          <Item.Group>
            <Item>
              <Item.Content>
                <Header sub>По общим вопросам:</Header>
                <Item.Description>
                  <a href='mailto:go@caddygo.ru'>go@caddygo.ru</a>
                </Item.Description>
              </Item.Content>
            </Item>

            <Item>
              <Item.Content>
                <Header sub>Сотрудничество:</Header>
                <Item.Description>
                  <PhoneNumber phoneNumber={salesPhoneNumber} />
                </Item.Description>
              </Item.Content>
            </Item>

            <Item>
              <Item.Content>
                <Header sub>Технические вопросы:</Header>
                <Item.Description>
                  <PhoneNumber phoneNumber={techPhoneNumber} />
                </Item.Description>
              </Item.Content>
            </Item>
          </Item.Group>
        </Segment>
      </Segment.Group>
    </Container>
  );
};

export default FeedbackPage;

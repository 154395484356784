import React from 'react';
import { useSelector } from 'react-redux';
// import TestModal from '../../../features/sandbox/TestModal';
// import LoginForm from '../../../features/auth/LoginForm';
// import RegisterForm from '../../../features/auth/RegisterForm';
import EmailLoginForm from '../../../features/auth/EmailLoginForm';
import OrderDetailedPaymentModal from '../../../features/orders/orderDetailed/OrderDetailedPaymentModal';
import OrderDetailedCaddyRespondModal from '../../../features/orders/orderDetailed/OrderDetailedCaddyRespondModal';

const ModalManager = () => {
  const modalLookup = { OrderDetailedPaymentModal, OrderDetailedCaddyRespondModal, EmailLoginForm };
  const currentModal = useSelector((state) => state.modals);
  let renderedModal;

  if (currentModal) {
    const { modalType, modalProps } = currentModal;
    const ModalComponent = modalLookup[modalType];
    renderedModal = <ModalComponent {...modalProps} />;
  }
  return <span>{renderedModal}</span>;
};

export default ModalManager;

import React from 'react';
import { Icon } from 'semantic-ui-react';
import { formatPhoneNumber } from '../../app/common/util/util';

const PhoneNumber = ({ phoneNumber }) => {
  return (
    <>
      <a href={'tel:' + phoneNumber} title='Позвонить'>
        {formatPhoneNumber(phoneNumber)}
      </a>
      <a href={'https://wa.me/' + +phoneNumber} title='Открыть в Whatsapp' target='blank'>
        {' '}
        <Icon name='whatsapp square' color='green' style={{ fontSize: '1.2em' }} />
      </a>
      <a href={'viber://chat?number=' + phoneNumber} title='Открыть в Viber' target='blank'>
        <Icon name='viber' color='violet' style={{ fontSize: '1.1em' }} />
      </a>
    </>
  );
};

export default PhoneNumber;

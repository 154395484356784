import {
  asyncActionError,
  asyncActionFinish,
  asyncActionStart,
} from '../../app/async/asyncReducer';
import {
  CLEAR_ORDERS,
  CLEAR_SELECTED_ORDER,
  CREATE_ORDER,
  DELETE_ORDER,
  FETCH_ORDERS,
  LISTEN_TO_ORDER_COMMENTS,
  LISTEN_TO_SELECTED_ORDER,
  SET_CADDY_CHOICE_FILTER,
  SET_START_DATE,
  SET_TIME_FILTER,
  SET_TYPE_FILTER,
  UPDATE_ORDER,
} from './orderConstants';
import { dataFromSnapshot, fetchOrdersFromFirestore } from '../../app/firestore/firestoreService';

export const fetchOrders = (
  typeFilter,
  timeFilter,
  caddyChoiceFilter,
  startDate,
  limit,
  lastDocSnapshot
) => {
  return async (dispatch) => {
    dispatch(asyncActionStart());
    try {
      const snapshot = await fetchOrdersFromFirestore(
        typeFilter,
        timeFilter,
        caddyChoiceFilter,
        startDate,
        limit,
        lastDocSnapshot
      ).get();

      const lastVisible = snapshot.docs[snapshot.docs.length - 1];
      const moreOrders = snapshot.docs.length >= limit;
      const orders = snapshot.docs.map((doc) => dataFromSnapshot(doc));

      // console.log(orders);

      dispatch({
        type: FETCH_ORDERS,
        payload: { orders, moreOrders, lastVisible },
      });
      dispatch(asyncActionFinish());
    } catch (error) {
      dispatch(asyncActionError(error));
    }
  };
};

export const setTypeFilter = (value) => {
  return (dispatch) => {
    dispatch(clearOrders());
    dispatch({ type: SET_TYPE_FILTER, payload: value });
  };
};

export const setTimeFilter = (value) => {
  return (dispatch) => {
    dispatch(clearOrders());
    dispatch({ type: SET_TIME_FILTER, payload: value });
  };
};

export const setCaddyChoiceFilter = (value) => {
  return (dispatch) => {
    dispatch(clearOrders());
    dispatch({ type: SET_CADDY_CHOICE_FILTER, payload: value });
  };
};

export const setStartDate = (date) => {
  return (dispatch) => {
    dispatch(clearOrders());
    dispatch({ type: SET_START_DATE, payload: date });
  };
};

export const listenToSelectedOrder = (order) => {
  return {
    type: LISTEN_TO_SELECTED_ORDER,
    payload: order,
  };
};

export const listenToOrderComments = (comments) => {
  return {
    type: LISTEN_TO_ORDER_COMMENTS,
    payload: comments,
  };
};

export const clearSelectedOrder = () => {
  return {
    type: CLEAR_SELECTED_ORDER,
  };
};

export const createOrder = (order) => {
  return {
    type: CREATE_ORDER,
    payload: order,
  };
};

export const updateOrder = (order) => {
  return {
    type: UPDATE_ORDER,
    payload: order,
  };
};

export const deleteOrder = (orderId) => {
  return {
    type: DELETE_ORDER,
    payload: orderId,
  };
};

export const clearOrders = () => {
  return {
    type: CLEAR_ORDERS,
  };
};

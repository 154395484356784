import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Button, Container, FormField, Input } from 'semantic-ui-react';
import { closeModal } from '../../../app/common/modals/modalReducer';
import ModalWrapper from '../../../app/common/modals/ModalWrapper';

const OrderDetailedCaddyRespondModal = ({ loading, handleCaddyRespond }) => {
  const dispatch = useDispatch();
  const [customPrice, setCustomPrice] = useState('');

  return (
    <ModalWrapper size='mini' header='Задать цену'>
      <span>
        Введите сумму в рублях, по которой Вы готовы откликнуться на эту заявку:
        <br />
        <br />
      </span>
      <Container textAlign='center'>
        <FormField>
          <Input type='number' onChange={(e) => setCustomPrice(e.target.value)} />
        </FormField>
        <Button
          loading={loading}
          onClick={() => {
            handleCaddyRespond('responded', customPrice);
            dispatch(closeModal());
          }}
          color='green'
          style={{ margin: 10 }}
          content='Откликнуться'
        />
      </Container>
    </ModalWrapper>
  );
};

export default OrderDetailedCaddyRespondModal;

import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Grid } from 'semantic-ui-react';
import { getUserProfile } from '../../../app/firestore/firestoreService';
import useFirestoreDoc from '../../../app/hooks/useFirestoreDoc';
import { listenToSelectedUserProfile } from '../profileActions';
import ProfileContent from './ProfileContent';
import ProfileHeader from './ProfileHeader';
import LoadingComponent from '../../../app/layout/LoadingComponent';

const ProfilePage = ({ match }) => {
  const dispatch = useDispatch();
  const { selectedUserProfile, currentUserProfile } = useSelector((state) => state.profile);

  const { loading, error } = useSelector((state) => state.async);
  const { currentUser } = useSelector((state) => state.auth);
  const { isModer } = currentUserProfile || false;
  // console.log(currentUser);

  useFirestoreDoc({
    query: () => getUserProfile(match?.params?.id),
    data: (profile) => dispatch(listenToSelectedUserProfile(profile)),
    deps: [dispatch, match?.params?.id],
    shouldExecute: match?.params?.id !== currentUser.uid,
  });

  const profile =
    match?.params?.id === currentUser.uid || !match?.params?.id
      ? currentUserProfile
      : selectedUserProfile;

  if ((loading && !profile) || (!profile && !error))
    return <LoadingComponent content='Загрузка профиля...' />;

  return (
    <div className='ui stackable container'>
      <Grid centered doubling>
        <Grid.Column width={16}>
          <ProfileHeader profile={profile} isCurrentUser={currentUser.uid === profile.id} />
          <ProfileContent
            width={16}
            profile={profile}
            isCurrentUser={currentUser.uid === profile.id}
            isModer={isModer}
          />
        </Grid.Column>
      </Grid>
    </div>
  );
};

export default ProfilePage;

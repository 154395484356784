import cuid from 'cuid';
import React, { useState } from 'react';
import { toast } from 'react-toastify';
import { Button, Grid, Header } from 'semantic-ui-react';
import { uploadToFirebaseStorage } from '../../firestore/firebaseService';
import { updateUserProfilePhoto } from '../../firestore/firestoreService';
import { getFileExtension } from '../util/util';
import PhotoWidgetCropper from './PhotoWidgetCropper';
import { PhotoWidgetDropzone } from './PhotoWidgetDropzone';

const PhotoUploadWidget = ({ setEditMode }) => {
  const [files, setFiles] = useState([]);
  const [image, setImage] = useState(null);
  const [loading, setLoading] = useState(false);

  const handleUploadImage = () => {
    setLoading(true);
    const filename = `${cuid()}.${getFileExtension(files[0].name)}`;
    const uploadTask = uploadToFirebaseStorage(image, filename);
    uploadTask.on(
      'state_changed',
      (snapshot) => {
        // const progress =
        //   (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        // console.log('Загружено', progress, '%');
      },
      (error) => {
        toast.error(error.message);
      },
      () => {
        uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
          updateUserProfilePhoto(downloadURL, filename)
            .then(() => {
              setLoading(false);
              handleCancelCrop();
              setEditMode(false);
            })
            .catch((error) => {
              setLoading(false);
              toast.error(error.message);
            });
        });
      }
    );
  };

  const handleCancelCrop = () => {
    setFiles([]);
    setImage(null);
  };

  return (
    <Grid stackable centered columns={3}>
      <Grid.Column textAlign='center'>
        <Header color='teal' sub content='1. Загрузка фото' />
        <PhotoWidgetDropzone setFiles={setFiles} />
      </Grid.Column>

      <Grid.Column textAlign='center'>
        <Header color='teal' sub content='2. Редактирование изображения' />
        {files.length > 0 && (
          <PhotoWidgetCropper
            setImage={setImage}
            imagePreview={files[0].preview}
          />
        )}
      </Grid.Column>

      <Grid.Column textAlign='center'>
        <Header color='teal' sub content='3. Предпросмотр и загрузка' />
        {files.length > 0 && (
          <>
            <div
              className='img-preview'
              style={{
                minHeight: 200,
                minWidth: 200,
                overflow: 'hidden',
                margin: 'auto',
              }}
            />
            <Button.Group>
              <Button
                loading={loading}
                onClick={handleUploadImage}
                style={{ width: 100 }}
                positive
                icon='check'
              />
              <Button
                disabled={loading}
                onClick={handleCancelCrop}
                style={{ width: 100 }}
                icon='close'
              />
            </Button.Group>
          </>
        )}
      </Grid.Column>
    </Grid>
  );
};

export default PhotoUploadWidget;

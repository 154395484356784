import React from 'react';
import { Container, Header, Segment } from 'semantic-ui-react';

const CaddySchoolPage = () => {
  return (
    <Container text>
      <Segment.Group>
        <Segment>
          <Header sub>Как стать кедди и начать зарабатывать в CaddyGo?</Header>
        </Segment>
        <Segment>
          <p>
            Чтобы стать профессиональным кедди, требуется время, терпение и упорство. Таких кедди в
            России мало, ценятся они на вес золота и их всегда не хватает.
          </p>
          <p>
            Чтобы исправить ситуацию, CaddyGo открыл свою собственную школу подготовки
            высококвалифицированных кедди!
          </p>
          <p>
            Интенсивный курс проходит в течение трех полных учебных дней. За это время будущие
            профессионалы подробно знакомятся с правилами гольфа, разбирают нестандартные ситуации
            на поле, узнают принципы организации работы кэдди, получают базовые знания по психологии
            взаимодействия с игроком. Завершает курс день практических занятий и тестирование
            (практическое и теоретическое).
          </p>
          <p>
            Такая структура курса покрывает все основные знания и навыки, необходимые грамотному
            помощнику игрока. Кедди в большинстве ситуаций на поле является единственным человеком,
            который может давать игроку советы. И вместе с этим игрок отвечает за все действия кэдди
            во время раунда.
          </p>
          <p>
            Поэтому так важно, чтобы кэдди хорошо разбирался в том, что можно, а что нельзя делать
            на поле. Нет ничего хуже, чем неумелое (или необдуманное) действие кэдди, в результате
            которого игрок запишет себе штрафной, а может даже и не один! Поэтому в школьном курсе
            раздел правил занимает самое большое место.
          </p>
          <p>
            Работа кэдди состоит совсем не в переноске бэга с клюшками, а в оказании игроку
            всесторонней помощи и поддержки во время раунда. Правильно оценить психологическое
            состояние игрока и вовремя его поддержать – это критически важно для эффективного
            раунда. И этому тоже учит школа CaddyGo.
          </p>
          <p>
            И, конечно, никакая теория не заменит практические занятия. На них кэдди узнают как
            перемещаться по полю, где оставлять тележку, как обращаться с инвентарем игрока и многое
            другое.
          </p>
          <p>
            CaddyGo справедливо считают, что должны обеспечивать наших гольфистов только грамотными
            и тренированными кэдди и делают для этого все необходимое. Самое главное для наших
            студентов – это возможность получить доступ к лучшим гольф-событиям России и лучшим
            игрокам через уникальное приложение CaddyGo, которое позволяет получить
            высокооплачиваемую любимую работу за один клик!
          </p>
          <p>
            В конце обучения каждый студент проходит аттестацию. По итогам теоретического (35
            вопросов по темам: инвентарь, правила, психология) и практического (прохождение лунок на
            поле в качестве Кедди) экзаменов школа присваивает статус кедди и выдаёт сертификат
            стандарта CaddyGo. Данные атрибуты позволяют попасть студентам в базу данных кедди и
            получить доступ к лучшим гольф-мероприятиям и игрокам и сразу начать получать
            практические навыки на престижных турнирах. Кедди получают за свою работу
            вознаграждение, в зависимости от своего статуса CaddyGo, от 3.500₽ до 15.000₽ за игру.
          </p>

          <p>Занятия проходят каждый месяц в Московском Городском Гольф Клубе.</p>
        </Segment>
        <Segment>
          <p>Узнать подробнее про Школу Кедди и записаться на обучение можно по адресу:</p>
          <p>
            <a href='https://school.caddygo.ru' target='_blanc'>
              school.caddygo.ru
            </a>
          </p>
        </Segment>
      </Segment.Group>
    </Container>
  );
};

export default CaddySchoolPage;

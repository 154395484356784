import React, { useEffect, useState } from 'react';
import { Divider, Grid, Loader } from 'semantic-ui-react';
import OrderList from './OrderList';
import { useDispatch, useSelector } from 'react-redux';
import { useWindowResize } from 'react-resize-tracker';
import OrderListItemPlaceholder from './OrderListItemPlaceholder';
import OrderFilters from '../OrderFilters';
import useFirestoreDoc from '../../../app/hooks/useFirestoreDoc';
import { clearOrders, fetchOrders, setTypeFilter } from '../orderActions';
import { listenToSelectedUserProfile } from '../../profiles/profileActions';
import { getUserProfile } from '../../../app/firestore/firestoreService';
import NotificationsList from '../../Notifications/NotificationsList';
// import { RETAIN_STATE } from '../orderConstants';

const OrderDashboard = () => {
  const limit = 3;
  const dispatch = useDispatch();
  const [currentWidth] = useWindowResize();
  const {
    orders,
    moreOrders,
    typeFilter,
    timeFilter,
    caddyChoiceFilter,
    startDate,
    lastVisible,
    // retainState,
  } = useSelector((state) => state.order);
  const { loading } = useSelector((state) => state.async);
  const [loadingInitial, setLoadingInitial] = useState(false);

  const { currentUser } = useSelector((state) => state.auth);
  const { currentUserProfile } = useSelector((state) => state.profile);
  const { isModer, isCaddy } = currentUserProfile || false;

  const typeFilterDefaultValue = isModer ? 'isModer' : isCaddy ? 'caddyRequests' : 'isCreator';
  // console.log('typeFilterDefaultValue:', typeFilterDefaultValue);

  useEffect(() => {
    // if (retainState) return;

    if (!typeFilter && currentUserProfile) {
      return dispatch(setTypeFilter(typeFilterDefaultValue));
    }
    if (typeFilter && currentUserProfile) {
      setLoadingInitial(true);
      dispatch(fetchOrders(typeFilter, timeFilter, caddyChoiceFilter, startDate, limit)).then(
        () => {
          setLoadingInitial(false);
        }
      );
    }
    return () => {
      // dispatch({ type: RETAIN_STATE });
      dispatch(clearOrders());
    };
  }, [
    dispatch,
    // retainState,
    typeFilter,
    timeFilter,
    caddyChoiceFilter,
    startDate,
    typeFilterDefaultValue,
    currentUserProfile,
  ]);

  const handleFetchNextOrders = () => {
    dispatch(fetchOrders(typeFilter, timeFilter, caddyChoiceFilter, startDate, limit, lastVisible));
  };

  useFirestoreDoc({
    query: () => getUserProfile(currentUser.uid),
    data: (profile) => dispatch(listenToSelectedUserProfile(profile)),
    deps: [dispatch, currentUser.uid],
  });

  return (
    <Grid centered>
      <Grid.Column width={currentWidth > 700 ? 10 : 16}>
        {loadingInitial && (
          <>
            <OrderListItemPlaceholder />
            <OrderListItemPlaceholder />
          </>
        )}
        {currentWidth < 700 && (
          <OrderFilters isCaddy={isCaddy} isModer={isModer} loading={loading} mobile={true} />
        )}
        <OrderList
          orders={orders}
          getNextOrders={handleFetchNextOrders}
          loading={loading}
          moreOrders={moreOrders}
        />
      </Grid.Column>

      {currentWidth > 700 && (
        <Grid.Column width={6}>
          <OrderFilters isCaddy={isCaddy} isModer={isModer} loading={loading} />
          <Divider />
          <NotificationsList />
        </Grid.Column>
      )}

      <Grid.Column width={currentWidth > 700 ? 10 : 16}>
        <Loader active={loading} />
      </Grid.Column>
    </Grid>
  );
};

export default OrderDashboard;

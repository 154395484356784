import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink, useHistory } from 'react-router-dom';
import { Confirm, Menu } from 'semantic-ui-react';
import { signOutFirebase } from '../../app/firestore/firebaseService';
import { signOutUser } from '../auth/authActions';
import { toast } from 'react-toastify';

const MobileSidebarMenu = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [logoutConfirmOpen, setLogoutConfirmOpen] = useState(false);
  const { currentUserProfile } = useSelector((state) => state.profile);
  const { isModer } = currentUserProfile || false;

  const handleSignOut = async () => {
    try {
      await dispatch(signOutUser());
      await signOutFirebase();
      history.push('/login');
    } catch (error) {
      toast.error(error.message);
    }
  };

  return (
    <>
      <Menu vertical size='huge' fluid>
        <Menu.Item as={NavLink} to={`/profile/`} icon='user' content='Профиль' />
        <Menu.Item as={NavLink} to={`/settings`} icon='settings' content='Настройки аккаунта' />
        {/* <Menu.Item
          as={NavLink}
          to={`/howto`}
          icon='question circle outline'
          content='Как пользоваться приложением'
        />
        <Menu.Item as={NavLink} to={`/about`} icon='medapps' content='О проекте CaddyGo' /> */}
        <Menu.Item
          as={NavLink}
          to={`/caddy-school`}
          icon='star outline'
          content='Как стать кедди?'
        />
        <Menu.Item as={NavLink} to={`/feedback`} icon='send' content='Обратная связь' />
      </Menu>

      <Menu vertical size='huge' fluid>
        <Menu.Item as={NavLink} to={`/golfboard`} icon='rocket' content='Гольфборды CaddyGo' />
        {/* <Menu.Item as={NavLink} to={`/tour`} icon='winner' content='Турнир CaddyGo 850.000₽' /> */}
        {isModer && <Menu.Item as={NavLink} to={`/caddys`} icon='list' content='База кедди' />}
      </Menu>

      <Menu vertical size='huge' fluid>
        <Menu.Item onClick={() => setLogoutConfirmOpen(true)} icon='log out' content='Выход' />
      </Menu>

      <Confirm
        content='Вы уверены, что хотите выйти из аккаунта?'
        cancelButton='Отмена'
        confirmButton='Подтвердить'
        open={logoutConfirmOpen}
        onCancel={() => setLogoutConfirmOpen(false)}
        onConfirm={handleSignOut}
      />
    </>
  );
};

export default MobileSidebarMenu;

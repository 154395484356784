import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useWindowResize } from 'react-resize-tracker';
import { Link, useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Divider, Dropdown, Image, Menu } from 'semantic-ui-react';
import { signOutFirebase } from '../../app/firestore/firebaseService';
import { signOutUser } from '../auth/authActions';

const SignedInMenu = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { currentUserProfile } = useSelector((state) => state.profile);
  const [currentWidth] = useWindowResize();

  const handleSignOut = async () => {
    try {
      await dispatch(signOutUser());
      await signOutFirebase();
      history.push('/login');
    } catch (error) {
      toast.error(error.message);
    }
  };

  return (
    <Menu.Item position='right'>
      <Image
        avatar
        spaced='right'
        src={currentUserProfile?.photoURL || '/assets/user.png'}
        as={Link}
        to={`/profile/${currentUserProfile?.id}`}
      />
      <Dropdown
        className='ui dropdown'
        pointing='top right'
        text={currentWidth > 700 ? currentUserProfile?.displayName : 'Профиль'}
      >
        <Dropdown.Menu>
          {/* <Dropdown.Item as={Link} to='/new' text='Новая заявка' icon='plus' /> */}
          <Dropdown.Item as={Link} to='/profile/' text='Профиль' icon='user' />
          <Dropdown.Item as={Link} to='/settings' text='Настройки аккаунта' icon='setting' />
          <Dropdown.Item as={Link} to='/caddy-school' text='Как стать кедди?' icon='star outline' />
          <Dropdown.Item as={Link} to='/feedback' text='Обратная связь' icon='send' />
          <Divider />
          <Dropdown.Item as={Link} to='/golfboard' text='Гольфборды CaddyGo' icon='rocket' />
          {/* <Dropdown.Item as={Link} to='/tour' text='Турнир CaddyGo' icon='winner' /> */}
          <Divider />
          <Dropdown.Item onClick={handleSignOut} text='Выход' icon='log out' />
        </Dropdown.Menu>
      </Dropdown>
    </Menu.Item>
  );
};

export default SignedInMenu;

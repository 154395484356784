import React from 'react';
import { useDispatch } from 'react-redux';
import { Button, Container, Icon, Segment } from 'semantic-ui-react';
import { closeModal } from '../../../app/common/modals/modalReducer';
import ModalWrapper from '../../../app/common/modals/ModalWrapper';
import { formatPhoneNumber } from '../../../app/common/util/util';

const OrderDetailedPaymentModal = ({ loading, handlePaymentConfirm, price }) => {
  const dispatch = useDispatch();
  const phone = process.env.REACT_APP_ADMIN_PHONE_NUMBER;
  const card = process.env.REACT_APP_ADMIN_PAYMENT_CARD;

  return (
    <ModalWrapper size='mini' header='Оплата'>
      <span>
        Для оплаты переведите {price} руб. на карту (Сбербанк): <br /> <br />
        {card} <br /> <br />
        или по номеру телефона: <br />
        <br />
        <a href={'tel:' + phone}>{formatPhoneNumber(phone)}</a>
        <br />
        <br />
        Получатель: Максим Андреевич Ш.
        <br /> <br />
        В примечании к переводу укажите только Ваше имя и фамилию (аналогично тем, которые Вы
        указали в своем профиле CaddyGo) <br />
        <br />
        После перевода нажмите кнопку «Подтвердить оплату» ниже:
        <br />
        <br />
      </span>
      <Container textAlign='center'>
        <Button
          loading={loading}
          onClick={() => {
            handlePaymentConfirm('paymentSent');
            dispatch(closeModal());
          }}
          color='green'
          // floated='right'
          style={{ margin: 10 }}
          // content='Оплатить'
        >
          <Icon name='payment' className='caddy-choice' />
          Подтвердить оплату
        </Button>
      </Container>
    </ModalWrapper>
  );
};

export default OrderDetailedPaymentModal;

// import cuid from 'cuid';
import firebase from '../config/firebase';
const db = firebase.firestore();

export const dataFromSnapshot = (snapshot) => {
  if (!snapshot.exists) return undefined;
  const data = snapshot.data();

  for (const prop in data) {
    if (data.hasOwnProperty(prop)) {
      if (data[prop] instanceof firebase.firestore.Timestamp) {
        data[prop] = data[prop].toDate();
      }
    }
  }

  return {
    ...data,
    id: snapshot.id,
  };
};

export const fetchOrdersFromFirestore = (
  typeFilter,
  timeFilter,
  caddyChoiceFilter,
  startDate,
  limit,
  lastDocSnapshot = null
) => {
  const user = firebase.auth().currentUser;
  const today = startDate || new Date();
  const dateCondition = timeFilter === 'past' ? '<=' : '>=';
  const orderDirection = timeFilter === 'past' ? 'desc' : 'asc';

  let ordersRef = db.collection('CaddyOrders').orderBy('date', orderDirection).limit(limit);
  if (lastDocSnapshot) ordersRef = ordersRef.startAfter(lastDocSnapshot);

  // const caddyChoiceFilter = predicate.get('caddyChoiceFilter');
  // console.log(orderDirection);

  switch (typeFilter) {
    case 'caddyRequests':
      if (caddyChoiceFilter === 'all') {
        return ordersRef.where('date', dateCondition, today).where('caddys', 'array-contains-any', [
          {
            caddyId: user.uid,
            status: 'responded',
          },
          {
            caddyId: user.uid,
            status: 'declined',
          },
          {
            caddyId: user.uid,
            status: 'new',
          },
          {
            caddyId: user.uid,
            status: 'approved',
          },
        ]);
      } else {
        return ordersRef.where('date', dateCondition, today).where('caddys', 'array-contains', {
          caddyId: user.uid,
          status: caddyChoiceFilter,
        });
      }

    case 'isModer':
      return (
        ordersRef
          // .where('creatorUid', '!=', user.uid)
          .where('date', dateCondition, today)
      );

    case 'isCreator':
      return ordersRef.where('creatorUid', '==', user.uid).where('date', dateCondition, today);

    default:
      return null;
  }
};

export const listenToOrderFromFirestore = (orderId) => {
  return db.collection('CaddyOrders').doc(orderId);
};

export const addOrderToFirestore = async (order) => {
  const user = firebase.auth().currentUser;
  const caddys = [];
  const caddysProfiles = [];

  let caddysRef = await db
    .collection('Users')
    .where('isCaddy', '==', true)
    .where('caddyStatus', '==', 'active');

  if (order.caddyLevel?.length > 0 && !order.caddyLevel.includes('any'))
    caddysRef = await caddysRef.where('caddyLevel', 'in', order.caddyLevel);
  if (order.caddyGender && order.caddyGender !== 'any')
    caddysRef = await caddysRef.where('gender', '==', order.caddyGender);

  caddysRef = await caddysRef.get();

  await caddysRef.forEach((caddy) => {
    // console.log(caddy.id);
    if (caddy.id === user.uid) return;
    caddysProfiles.push({
      caddyId: caddy.id,
      photoURL: caddy.data().photoURL,
      displayName: caddy.data().displayName,
      email: caddy.data().email || null,
      phoneNumber: caddy.data().phoneNumber || null,
      caddyPrice: caddy.data().caddyPrice || 0,
      caddyLevel: caddy.data().caddyLevel || 'new',
      caddySince: caddy.data().caddySince || null,
      achievements: caddy.data().achievements || null,
      driversLicense: caddy.data().driversLicense || null,
    });
    caddys.push({
      caddyId: caddy.id,
      status: 'new',
    });
  });

  // console.log(caddysProfiles);

  const data = {
    ...order,
    creationDate: new Date(),
    creatorUid: user.uid,
    creator: user.displayName,
    creatorPhotoURL: user.photoURL || null,
    paymentStatus: 'unpaid',
    caddys,
    caddysProfiles,
  };

  // console.log(data);

  return db.collection('CaddyOrders').add(data);
};

export const updateOrderInFirestore = (order) => {
  return db.collection('CaddyOrders').doc(order.id).update(order);
};

export const deleteOrderInFirestore = (orderId) => {
  return db.collection('CaddyOrders').doc(orderId).delete();
};

export const cancelOrderToggle = (order) => {
  return db.collection('CaddyOrders').doc(order.id).update({
    isCancelled: !order.isCancelled,
  });
};

export const updateOrderPaymentStatus = (order, status) => {
  return db.collection('CaddyOrders').doc(order.id).update({
    paymentStatus: status,
  });
};

export const setUserProfileData = async (user) => {
  console.log(user);
  // if (user.providerData[0].providerId === 'facebook.com') {
  //   // console.log(user.birthday);
  // }

  return await db
    .collection('Users')
    .doc(user.uid)
    .set({
      firstName: user.firstName || user.displayName,
      lastName: user.lastName || '',
      displayName: user.displayName || `${user.firstName} ${user.lastName}`,
      email: user.customEmail || user.email || null,
      phoneNumber: user.phoneNumber || user.phone || null,
      photoURL: user.photoURL || null,
      gender: user.gender || null,
      birthday: user.birthday || null,
      regDate: firebase.firestore.FieldValue.serverTimestamp(),
    });
};

export const getUserProfile = (userId) => {
  return db.collection('Users').doc(userId);
};

export const updateUserProfile = async (profile) => {
  profile.displayName = `${profile.firstName} ${profile.lastName}`;
  const user = firebase.auth().currentUser;

  try {
    if (user.displayName !== profile.displayName) {
      await user.updateProfile({
        displayName: profile.displayName,
      });
    }
    return await db.collection('Users').doc(user.uid).update(profile);
  } catch (error) {
    throw error;
  }
};

export const updateUserProfilePhoto = async (downloadURL, filename) => {
  const user = firebase.auth().currentUser;
  const userDocRef = db.collection('Users').doc(user.uid);

  try {
    const userDoc = await userDocRef.get();
    if (!userDoc.data().photoURL) {
      await db.collection('Users').doc(user.uid).update({
        photoURL: downloadURL,
      });
      await user.updateProfile({
        photoURL: downloadURL,
      });
    }

    return await db.collection('Users').doc(user.uid).collection('photos').add({
      name: filename,
      url: downloadURL,
    });
  } catch (error) {
    throw error;
  }
};

export const getUserPhotos = (userUid) => {
  return db.collection('Users').doc(userUid).collection('photos');
};

export const setUserMainPhoto = async (photo) => {
  const user = firebase.auth().currentUser;
  const today = new Date();

  const orderAsCreatorDocQuery = db
    .collection('CaddyOrders')
    .where('date', '>=', today)
    .where('creatorUid', '==', user.uid);

  const orderAsCaddyDocQuery = db
    .collection('CaddyOrders')
    .where('date', '>=', today)
    .where('caddys', 'array-contains-any', [
      {
        caddyId: user.uid,
        status: 'responded',
      },
      {
        caddyId: user.uid,
        status: 'declined',
      },
      {
        caddyId: user.uid,
        status: 'new',
      },
      {
        caddyId: user.uid,
        status: 'approved',
      },
    ]);

  const batch = db.batch();
  batch.update(db.collection('Users').doc(user.uid), {
    photoURL: photo.url,
  });

  try {
    const orderAsCaddyDocSnapshot = await orderAsCaddyDocQuery.get();
    const orderAsCreatorDocSnapshot = await orderAsCreatorDocQuery.get();

    // for (let i = 0; i < orderAsCaddyDocSnapshot.docs.length; i++) {
    // let orderDoc = orderAsCaddyDocSnapshot.docs[i];
    await orderAsCreatorDocSnapshot.docs.forEach((orderDoc) => {
      if (orderDoc.data().creatorUid === user.uid) {
        console.log(orderDoc.data());
        batch.update(db.collection('CaddyOrders').doc(orderDoc.id), {
          creatorPhotoURL: photo.url,
        });
      }
    });

    await orderAsCaddyDocSnapshot.docs.forEach((orderDoc) => {
      console.log(orderDoc.data());
      batch.update(db.collection('CaddyOrders').doc(orderDoc.id), {
        caddysProfiles: orderDoc.data().caddysProfiles.filter((caddy) => {
          if (caddy.caddyId === user.uid) caddy.photoURL = photo.url;
          return caddy;
        }),
      });
    });

    console.log(batch);
    await batch.commit();

    return await user.updateProfile({
      photoURL: photo.url,
    });
  } catch (error) {
    throw error;
  }
};

export const deletePhotoFromCollection = (photoId) => {
  const userUid = firebase.auth().currentUser.uid;
  return db.collection('Users').doc(userUid).collection('photos').doc(photoId).delete();
};

export const updateOrderCaddyStatus = async (
  order,
  currentUserProfile,
  customPrice,
  status,
  isNew = false
) => {
  try {
    // console.log(currentUserProfile);
    const caddyId = currentUserProfile.id;
    const orderDoc = await db.collection('CaddyOrders').doc(order.id).get();
    let caddys = orderDoc.data().caddys;
    let caddysProfiles = orderDoc.data().caddysProfiles;

    if (customPrice) {
      let caddyIndex = caddysProfiles?.findIndex((caddy) => caddy?.caddyId === caddyId);
      if (caddyIndex && caddysProfiles[caddyIndex])
        caddysProfiles[caddyIndex].caddyPrice = customPrice;
      // console.log('custom price added', customPrice);
    }

    if (isNew) {
      caddys.push({
        caddyId: caddyId,
        status: status,
      });

      caddysProfiles.push({
        caddyId: caddyId,
        displayName: currentUserProfile.displayName,
        photoURL: currentUserProfile.photoURL,
        caddyPrice: currentUserProfile.caddyPrice,
        caddyLevel: currentUserProfile.caddyLevel,
        caddySince: currentUserProfile.caddySince || null,
        email: currentUserProfile.email || null,
        phoneNumber: currentUserProfile.phoneNumber,
        achievements: currentUserProfile.achievements || null,
      });
    } else {
      caddys = caddys.map((caddy) => {
        if (caddy.caddyId !== caddyId) {
          return caddy;
        } else {
          // console.log('found');
          return {
            caddyId: caddyId,
            status: status,
          };
        }
      });
    }

    // console.log(caddys);
    // console.log(caddysProfiles);
    return db.collection('CaddyOrders').doc(order.id).update({ caddys, caddysProfiles });
  } catch (error) {
    throw error;
  }
};

export const getUserOrdersQuery = (activeTab, userUid) => {
  let ordersRef = db.collection('CaddyOrders');

  switch (activeTab) {
    case 1: // finishedOrders
      return ordersRef
        .where('caddys', 'array-contains-any', [
          {
            caddyId: userUid,
            status: 'approved',
          },
        ])
        .orderBy('date');

    case 2: // respondedOrders
      return ordersRef
        .where('caddys', 'array-contains-any', [
          {
            caddyId: userUid,
            status: 'responded',
          },
        ])
        .orderBy('date');

    case 3: // declinedOrders
      return ordersRef
        .where('caddys', 'array-contains-any', [
          {
            caddyId: userUid,
            status: 'declined',
          },
        ])
        .orderBy('date');

    default:
      //createdOrders
      return ordersRef.where('creatorUid', '==', userUid).orderBy('date');
  }
};

export const getCaddysProfiles = () => {
  return db.collection('Users').where('isCaddy', '==', true);
};

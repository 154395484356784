import { Form, Formik } from 'formik';
import React, { useState } from 'react';
import ModalWrapper from '../../app/common/modals/ModalWrapper';
import * as Yup from 'yup';
import TextInput from '../../app/common/form/TextInput';
import { Button, Label } from 'semantic-ui-react';
import { sendEmailSignInLink } from '../../app/firestore/firebaseService';

const EmailLoginForm = ({ linkToExistUser }) => {
  // const dispatch = useDispatch();
  const [isSend, setIsSend] = useState(false);

  return (
    <ModalWrapper
      size='mini'
      header={linkToExistUser ? 'Добавление входа по Email' : 'Вход через Email'}
    >
      {!isSend && (
        <Formik
          initialValues={{ email: '' }}
          validationSchema={Yup.object({
            email: Yup.string().required().email(),
          })}
          onSubmit={async (values, { setSubmitting, setErrors }) => {
            try {
              await sendEmailSignInLink(values.email, linkToExistUser);
              setSubmitting(false);
              setIsSend(true);
              // dispatch(closeModal());
            } catch (error) {
              setErrors({
                auth: 'Ошибка авторизации. Проверьте, корректно ли указаны данные',
              });
              setSubmitting(false);
            }
          }}
        >
          {({ isSubmitting, isValid, dirty, errors }) => (
            <Form className='ui form'>
              <TextInput name='email' placeholder='Укажите Ваш Email' />
              {errors.auth && (
                <Label basic color='red' style={{ marginBottom: 10 }} content={errors.auth} />
              )}
              <Button
                loading={isSubmitting}
                disabled={!isValid || !dirty || isSubmitting}
                type='submit'
                fluid
                size='large'
                color='teal'
                content={linkToExistUser ? 'Привязать' : 'Войти'}
              />
            </Form>
          )}
        </Formik>
      )}

      {isSend && (
        <span>
          Проверьте свою почту. <br />
          <br />
          Письмо со ссылкой для входа отправлено на указанный адрес
        </span>
      )}
    </ModalWrapper>
  );
};

export default EmailLoginForm;

import React from 'react';
// import { useDispatch } from 'react-redux';
import { Button, Menu } from 'semantic-ui-react';
// import { openModal } from '../../app/common/modals/modalReducer';
import { NavLink } from 'react-router-dom';

const SignedOutMenu = ({ setAuthenticated }) => {
  // const dispatch = useDispatch();
  return (
    <Menu.Item position='right'>
      <Button as={NavLink} to='/login' basic inverted content='Войти' />
      {/* <Button
        as={NavLink}
        to='/reg'
        basic
        inverted
        content='Регистрация'
        style={{ marginLeft: '0.5em' }}
      /> */}
    </Menu.Item>
  );
};

export default SignedOutMenu;

import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { Button, Card, Grid, Header, Image, Tab } from 'semantic-ui-react';
import PhotoUploadWidget from '../../../app/common/photos/PhotoUploadWidget';
import { deleteFromFirebaseStorage } from '../../../app/firestore/firebaseService';
import {
  deletePhotoFromCollection,
  getUserPhotos,
  setUserMainPhoto,
} from '../../../app/firestore/firestoreService';
import useFirestoreCollection from '../../../app/hooks/useFirestoreCollection';
import { listenToUserPhotos } from '../profileActions';

const PhotosTab = ({ profile, isCurrentUser }) => {
  const [editMode, setEditMode] = useState(false);
  const [updating, setUpdating] = useState({ isUpdating: false, target: null });
  const [deleting, setDeleting] = useState({ isDeleting: false, target: null });
  const dispatch = useDispatch();
  const { loading } = useSelector((state) => state.async);
  const { photos } = useSelector((state) => state.profile);

  useFirestoreCollection({
    query: () => getUserPhotos(profile.id),
    data: (photos) => dispatch(listenToUserPhotos(photos)),
    deps: [profile.id, dispatch],
  });

  const handleSetMainPhoto = async (photo, target) => {
    setUpdating({ isUpdating: true, target: target });
    try {
      await setUserMainPhoto(photo);
    } catch (error) {
      toast.error(error.message);
    } finally {
      setUpdating({ isUpdating: false, target: null });
    }
  };

  const handleDeletePhoto = async (photo, target) => {
    setDeleting({ isDeleting: true, target: target });
    try {
      await deleteFromFirebaseStorage(photo.name);
      await deletePhotoFromCollection(photo.id);
    } catch (error) {
      toast.error(error.message);
    } finally {
      setDeleting({ isDeleting: false, target: null });
    }
  };

  return (
    <Tab.Pane loading={loading} width={16}>
      <Grid>
        <Grid.Column width={16}>
          <Header floated='left' icon='user' content={`Фотографии`} />
          {isCurrentUser && (
            <Button
              onClick={() => setEditMode(!editMode)}
              floated='right'
              basic
              content={editMode ? 'Отмена' : 'Загрузить фото'}
            />
          )}
        </Grid.Column>
        <Grid.Column width={16}>
          {editMode ? (
            <PhotoUploadWidget setEditMode={setEditMode} />
          ) : (
            <Card.Group centered>
              {photos.map((photo) => (
                <Card key={photo.id}>
                  <Image src={photo.url} />
                  {isCurrentUser && (
                    <Button.Group fluid widths={2}>
                      <Button
                        name={photo.id}
                        loading={
                          updating.isUpdating && updating.target === photo.id
                        }
                        onClick={(event) =>
                          handleSetMainPhoto(photo, event.target.name)
                        }
                        disabled={photo.url === profile.photoURL}
                        basic
                        color='green'
                        icon='check'
                        content='Аватар'
                      />
                      <Button
                        name={photo.id}
                        loading={
                          deleting.isDeleting && deleting.target === photo.id
                        }
                        onClick={(event) =>
                          handleDeletePhoto(photo, event.target.name)
                        }
                        disabled={photo.url === profile.photoURL}
                        basic
                        color='red'
                        icon='trash'
                        content='Удалить'
                      />
                    </Button.Group>
                  )}
                </Card>
              ))}
            </Card.Group>
          )}
        </Grid.Column>
      </Grid>
    </Tab.Pane>
  );
};

export default PhotosTab;

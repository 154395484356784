import React from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Button } from 'semantic-ui-react';
import { openModal } from '../../app/common/modals/modalReducer';
import { socialLogin } from '../../app/firestore/firebaseService';

const SocialLogin = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const handleSocialLogin = async (provider) => {
    const res = await socialLogin(provider);
    // console.log(res);

    if (res?.additionalUserInfo?.isNewUser) {
      history.push({
        pathname: '/reg',
        userRes: res,
      });
    } else {
      history.push('/orders');
    }
  };

  return (
    <>
      <Button
        onClick={() => {
          handleSocialLogin('facebook');
        }}
        icon='facebook'
        fluid
        color='facebook'
        style={{ marginBottom: 10 }}
        content='Войти через Facebook'
      />

      <Button
        onClick={() => {
          handleSocialLogin('google');
        }}
        icon='google'
        fluid
        color='google plus'
        style={{ marginBottom: 10 }}
        content='Войти через Google'
      />

      <Button
        onClick={() => dispatch(openModal({ modalType: 'EmailLoginForm' }))}
        icon='mail'
        fluid
        color='linkedin'
        style={{ marginBottom: 10 }}
        content='Войти по ссылке через Email'
      />
    </>
  );
};

export default SocialLogin;

import React from 'react';
import {
  Container,
  Header,
  Segment,
  Image,
  Button,
  Grid,
} from 'semantic-ui-react';

const HomePage = ({ history }) => {
  return (
    <Segment inverted textAlign='center' vertical className='masthead'>
      <Container fluid textAlign='center'>
        <Grid fluid textAlign='center'>
          <Grid.Row>
            <Image
              wrapped
              size='medium'
              src='/assets/logo.png'
              style={{ marginBottom: 12 }}
            />
          </Grid.Row>
          <Grid.Row>
            <Header as='h2' inverted style={{ marginBottom: 20 }}>
              Заказ кедди в 1 клик
            </Header>
          </Grid.Row>

          <Grid.Row>
            <Button
              onClick={() => history.push('/login')}
              size='massive'
              // icon='right arrow'
              content='Начать'
              inverted
            />
          </Grid.Row>
        </Grid>
      </Container>
    </Segment>
  );
};

export default HomePage;

import React, { useRef } from 'react';
import Cropper from 'react-cropper';
import 'cropperjs/dist/cropper.css';

const PhotoWidgetCropper = ({ setImage, imagePreview }) => {
  const cropperRef = useRef(null);
  const cropImage = () => {
    if (typeof cropperRef.current.cropper.getCroppedCanvas() === 'undefined') return;

    cropperRef.current.cropper.getCroppedCanvas().toBlob((blob) => {
      setImage(blob);
    }, 'image/jpeg');

    // const imageElement = cropperRef?.current;
    // const cropper = imageElement?.cropper;
    // console.log(cropper.getCroppedCanvas().toDataURL());
  };

  return (
    <Cropper
      ref={cropperRef}
      src={imagePreview}
      style={{ height: 200, width: '100%' }}
      aspectRatio='1'
      preview='.img-preview'
      guides
      viewMode
      dragMode='move'
      cropBoxMovable
      cropBoxResizable
      rotatable
      checkOrientation={false}
      scalable
      responsive
      autoCropArea
      crop={cropImage}
    />
  );
};

export default PhotoWidgetCropper;

import { formatDistance } from 'date-fns';
import ru from 'date-fns/locale/ru';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Segment, Comment, Header } from 'semantic-ui-react';
import { createDataTree } from '../../../app/common/util/util';
import {
  deleteOrderComment,
  firebaseObjectToArray,
  getOrderCommentsRef,
} from '../../../app/firestore/firebaseService';
import { listenToOrderComments } from '../orderActions';
import { CLEAR_COMMENTS } from '../orderConstants';
import OrderDetailedCommentForm from './OrderDetailedCommentForm';

const OrderDetailedComments = ({ orderId }) => {
  const dispatch = useDispatch();
  const { comments } = useSelector((state) => state.order);
  const { currentUserProfile } = useSelector((state) => state.profile);
  const { isModer } = currentUserProfile || false;
  const [showReplyForm, setShowReplyForm] = useState({
    open: false,
    commentId: null,
  });

  const handleCloseReplyForm = () => {
    setShowReplyForm({ open: false, commentId: null });
  };

  const handleDeleteComment = (commentId) => {
    deleteOrderComment(orderId, commentId);
    dispatch({ type: CLEAR_COMMENTS });
  };

  useEffect(() => {
    getOrderCommentsRef(orderId).on('value', (snapshot) => {
      if (!snapshot.exists()) return;
      dispatch(listenToOrderComments(firebaseObjectToArray(snapshot.val()).reverse()));
    });
    return () => {
      dispatch({ type: CLEAR_COMMENTS });
      getOrderCommentsRef().off();
    };
  }, [orderId, dispatch]);

  return (
    <div style={{ margin: '30px 0' }}>
      <Segment textAlign='center' attached='top' inverted color='teal' style={{ border: 'none' }}>
        <Header as='h5'>Обсуждение бронирования</Header>
      </Segment>

      <Segment attached>
        <Comment.Group>
          {createDataTree(comments).map((comment) => (
            <Comment key={comment.id}>
              <Comment.Avatar src={comment.photoURL || '/assets/user.png'} />
              <Comment.Content>
                <Comment.Author as={Link} to={`/profile/${comment.uid}`}>
                  {comment.displayName}
                </Comment.Author>
                <Comment.Metadata>
                  {formatDistance(
                    new Date(comment.date || new Date()),
                    new Date(),
                    {
                      locale: ru,
                    },
                    { addSuffix: true }
                  )}{' '}
                  назад
                </Comment.Metadata>
                <Comment.Text>
                  {comment.text.split('\n').map((text, i) => (
                    <span key={i}>
                      {text}
                      <br />
                    </span>
                  ))}
                </Comment.Text>
                <Comment.Actions>
                  <Comment.Action
                    onClick={() => setShowReplyForm({ open: true, commentId: comment.id })}
                  >
                    Ответить
                  </Comment.Action>
                  {(comment.uid === currentUserProfile.id || isModer) && (
                    <Comment.Action onClick={() => handleDeleteComment(comment.id)}>
                      Удалить
                    </Comment.Action>
                  )}
                  {showReplyForm.open && showReplyForm.commentId === comment.id && (
                    <OrderDetailedCommentForm
                      orderId={orderId}
                      parentId={comment.id}
                      closeForm={handleCloseReplyForm}
                    />
                  )}
                </Comment.Actions>
              </Comment.Content>

              {comment.childNodes.length > 0 && (
                <Comment.Group>
                  {comment.childNodes.reverse().map((child) => (
                    <Comment key={child.id}>
                      <Comment.Avatar src={child.photoURL || '/assets/user.png'} />
                      <Comment.Content>
                        <Comment.Author as={Link} to={`/profile/${child.uid}`}>
                          {child.displayName}
                        </Comment.Author>
                        <Comment.Metadata>
                          {formatDistance(
                            new Date(child.date || new Date()),
                            new Date(),
                            {
                              locale: ru,
                            },
                            { addSuffix: true }
                          )}{' '}
                          назад
                        </Comment.Metadata>
                        <Comment.Text>
                          {child.text.split('\n').map((text, i) => (
                            <span key={i}>
                              {text}
                              <br />
                            </span>
                          ))}
                        </Comment.Text>
                        <Comment.Actions>
                          <Comment.Action
                            onClick={() => setShowReplyForm({ open: true, commentId: child.id })}
                          >
                            Ответить
                          </Comment.Action>
                          {(child.uid === currentUserProfile.id || isModer) && (
                            <Comment.Action onClick={() => handleDeleteComment(child.id)}>
                              Удалить
                            </Comment.Action>
                          )}
                          {showReplyForm.open && showReplyForm.commentId === child.id && (
                            <OrderDetailedCommentForm
                              orderId={orderId}
                              parentId={child.parentId}
                              closeForm={handleCloseReplyForm}
                            />
                          )}
                        </Comment.Actions>
                      </Comment.Content>
                    </Comment>
                  ))}
                </Comment.Group>
              )}
            </Comment>
          ))}

          <OrderDetailedCommentForm orderId={orderId} parentId={0} closeForm={setShowReplyForm} />
        </Comment.Group>
      </Segment>
    </div>
  );
};

export default OrderDetailedComments;

import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useWindowResize } from 'react-resize-tracker';
import { NavLink } from 'react-router-dom';
import { Container, Icon, Label, Menu } from 'semantic-ui-react';
import { getUserUnreadNotificationsCountRef } from '../../app/firestore/firebaseService';
import { listenToUserUnreadNotificationsCount } from '../profiles/profileActions';
import SignedInMenu from './SignedInMenu';
import SignedOutMenu from './SignedOutMenu';

const NavBar = () => {
  const { authenticated } = useSelector((state) => state.auth);
  const { notificationsCount } = useSelector((state) => state.profile);
  const [currentWidth] = useWindowResize();
  const dispatch = useDispatch();

  useEffect(() => {
    if (!authenticated) return;
    getUserUnreadNotificationsCountRef().on('value', (snapshot) => {
      const unreadCount = snapshot.numChildren();
      dispatch(listenToUserUnreadNotificationsCount(unreadCount));
      // console.log('unreadCount:', unreadCount);
    });
    return () => {
      getUserUnreadNotificationsCountRef().off();
    };
  }, [dispatch, authenticated]);

  return (
    <div>
      <Menu key='desktop' borderless inverted fixed='top' size='small'>
        <Container text>
          <Menu.Item
            as={NavLink}
            style={currentWidth < 700 ? { width: '100%', justifyContent: 'center' } : {}}
            exact
            to='/'
            header
          >
            <img src='/assets/logo.png' alt='logo' style={{ width: 170 }} />
          </Menu.Item>
          {authenticated && currentWidth > 700 && (
            <>
              <Menu.Item
                as={NavLink}
                to='/orders'
                icon='list'
                content={currentWidth > 700 ? 'Заявки' : ''}
              />

              <Menu.Item
                as={NavLink}
                to='/new'
                icon='plus'
                content={currentWidth > 700 ? 'Создать заявку' : 'Создать'}
              ></Menu.Item>

              <Menu.Item as={NavLink} to='/notifications'>
                <Icon name='bell' />
                {currentWidth > 990 ? 'Оповещения' : ''}
                {notificationsCount > 0 && (
                  <Label
                    color='orange'
                    circular
                    floating
                    content={notificationsCount === 100 ? '99+' : notificationsCount}
                  />
                )}
              </Menu.Item>

              <SignedInMenu />
            </>
          )}
          {!authenticated && <SignedOutMenu />}
        </Container>
      </Menu>

      {authenticated && currentWidth < 700 && (
        <>
          <Menu
            key='mobile'
            icon='labeled'
            compact
            borderless
            inverted
            fixed='bottom'
            size='small'
            widths={4}
            className='mobile-menu'
          >
            <Container text>
              <Menu.Item as={NavLink} to='/new' icon='plus' content='Создать'></Menu.Item>

              <Menu.Item as={NavLink} to='/orders' icon='list' content='Заявки' />

              <Menu.Item as={NavLink} to='/notifications'>
                {notificationsCount > 0 && (
                  <Label color='orange' circular floating>
                    {notificationsCount === 100 ? '99+' : notificationsCount}
                  </Label>
                )}
                <Icon name='bell' />
                Оповещения
              </Menu.Item>

              {/* <Menu.Item
              as={NavLink}
              to={`/profile/${currentUserProfile?.id}`}
              icon='user'
              content='Профиль'
            /> */}

              <Menu.Item
                // onClick={(e) => setVisibleSidebar(true)}
                as={NavLink}
                to={`/settings-menu`}
                icon='setting'
                content='Настройки'
              />
            </Container>
          </Menu>

          {/* <Sidebar.Pushable as={Segment}>
            <Sidebar
              as={Menu}
              animation='overlay'
              icon='labeled'
              direction='right'
              inverted
              onHide={() => setVisibleSidebar(false)}
              vertical
              visible={visibleSidebar}
              width='thin'
            >
              <Menu.Item as='a'>
                <Icon name='home' />
                Home
              </Menu.Item>
              <Menu.Item as='a'>
                <Icon name='gamepad' />
                Games
              </Menu.Item>
              <Menu.Item as='a'>
                <Icon name='camera' />
                Channels
              </Menu.Item>
            </Sidebar>

            <Sidebar.Pusher dimmed={visibleSidebar}>
              <Segment basic>
                <Header as='h3'>Application Content</Header>
                <Image src='https://react.semantic-ui.com/images/wireframe/paragraph.png' />
              </Segment>
              <div style={{ width: '100%', height: '90vh', position: 'fixed' }} />
            </Sidebar.Pusher>
          </Sidebar.Pushable> */}
        </>
      )}
    </div>
  );
};

export default NavBar;

import { useField } from 'formik';
import React from 'react';
import { FormField, Label, Select } from 'semantic-ui-react';

const SelectInput = ({ label, ...props }) => {
  const [field, meta, helpers] = useField(props);
  // if (!field.value) field.value = props.options[0].value;
  // console.log(props.options.find((e) => e.value === field.value).icon);
  // console.log(field.value);
  return (
    <FormField error={meta.touched && !!meta.error}>
      <label>{label}</label>
      <Select
        clearable
        value={field.value || null}
        // key='man'
        onChange={(e, data) => helpers.setValue(data.value)}
        onBlur={() => helpers.setTouched(true)}
        {...props}
      />
      {meta.touched && meta.error ? (
        <Label basic color='red'>
          {meta.error}
        </Label>
      ) : null}
    </FormField>
  );
};

export default SelectInput;

export const caddyLevel = [
  // { key: 'any', text: 'Любой', value: 'any' },
  { key: 'new', text: 'Начинающий', value: 'new' },
  { key: 'middle', text: 'Продвинутый', value: 'middle' },
  { key: 'pro', text: 'Профессиональный', value: 'pro' },
];

export const caddyGender = [
  { key: 'any', text: 'Любой', value: 'any', icon: 'intergender' },
  { key: 'male', text: 'Мужской', value: 'male', icon: 'man' },
  { key: 'female', text: 'Женский', value: 'female', icon: 'venus' },
];

export const golfClubs = [
  { key: 'skolkovo', text: 'Сколково', value: 'skolkovo' },
  { key: 'tseleevo', text: 'Целеево', value: 'tseleevo' },
  { key: 'zavidovo', text: 'Завидово', value: 'zavidovo' },
  { key: 'forest', text: 'Форест хиллс', value: 'forest' },
  { key: 'links', text: 'Линкс (Links National)', value: 'links' },
  { key: 'agalarov', text: 'Агаларов (Agalarov Golf)', value: 'agalarov' },
  { key: 'pestovo', text: 'Пестово', value: 'pestovo' },
  { key: 'pirigovo', text: 'Пирогово', value: 'pirigovo' },
  { key: 'krylatskoe', text: 'Крылатское', value: 'krylatskoe' },
  { key: 'kurkino', text: 'Куркино', value: 'kurkino' },
  { key: 'tiger', text: 'Тайгер (Tiger)', value: 'tiger' },
  { key: 'mcc', text: 'Moscow Country Club', value: 'mcc' },
  {
    key: 'mcgc',
    text: 'МГГК (Московский Городской Гольф Клуб)',
    value: 'mcgc',
  },
  { key: 'dmitrov', text: 'Dmitrov Golf Resort (Сорочаны)', value: 'dmitrov' },
];

export const paymentStatus = [
  { key: 'unpaid', text: 'пока не оплачено', value: 'unpaid' },
  { key: 'pending', text: 'в процессе оплаты', value: 'pending' },
  { key: 'paid', text: 'оплачено', value: 'paid' },
];

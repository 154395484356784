import React from 'react';
import OrderListItem from './OrderListItem';
import InfiniteScroll from 'react-infinite-scroller';

const OrderList = ({ orders, getNextOrders, loading, moreOrders }) => {
  // console.log(orders);
  return (
    <>
      {orders.length !== 0 && (
        <InfiniteScroll
          pageStart={0}
          loadMore={getNextOrders}
          hasMore={!loading && moreOrders}
          initialLoad={false}
        >
          {orders.map((order) => (
            <OrderListItem order={order} key={order.id} />
          ))}
        </InfiniteScroll>
      )}
    </>
  );
};

export default OrderList;

import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useWindowResize } from 'react-resize-tracker';
import { Grid } from 'semantic-ui-react';
import { listenToOrderFromFirestore } from '../../../app/firestore/firestoreService';
import useFirestoreDoc from '../../../app/hooks/useFirestoreDoc';
import OrderDetailedHeader from './OrderDetailedHeader';
import OrderDetailedInfo from './OrderDetailedInfo';
import OrderDetailedSidebar from './OrderDetailedSidebar';
import LoadingComponent from '../../../app/layout/LoadingComponent';
import { Redirect } from 'react-router';
import { listenToSelectedOrder } from '../orderActions';
import OrderDetailedComments from './OrderDetailedComments';

const OrderDetailedPage = ({ match }) => {
  const [currentWidth] = useWindowResize();
  const dispatch = useDispatch();
  const { currentUser } = useSelector((state) => state.auth);
  const { loading, error } = useSelector((state) => state.async);
  const order = useSelector((state) => state.order.selectedOrder);
  const { currentUserProfile } = useSelector((state) => state.profile);
  const { isModer } = currentUserProfile || false;

  const isCreator = order?.creatorUid === currentUser.uid;

  const status = order?.caddys?.find((caddy) => caddy.caddyId === currentUser.uid)?.status;
  // const status = false

  const approvedCaddys = order?.caddys?.filter((caddy) => {
    return caddy.status === 'approved';
  });

  const recruitmentFinish = approvedCaddys && approvedCaddys.length === +order.caddysLimit;

  const caddyPrice = approvedCaddys
    ? approvedCaddys.reduce(
        (sum, approvedCaddy) =>
          +sum +
          +order?.caddysProfiles?.find((elem) => elem.caddyId === approvedCaddy.caddyId)
            ?.caddyPrice,
        0
      )
    : 0;

  useFirestoreDoc({
    query: () => listenToOrderFromFirestore(match.params.id),
    data: (order) => dispatch(listenToSelectedOrder(order)),
    deps: [match.params.id, dispatch],
  });

  if (loading || (!order && !error)) return <LoadingComponent content='Loading' />;
  if (error) return <Redirect to='/error' />;

  return (
    <Grid columns={2}>
      <Grid.Row verticalAlign='top'>
        <Grid.Column width={currentWidth > 700 ? 10 : 16}>
          <OrderDetailedHeader
            order={order}
            currentUserProfile={currentUserProfile}
            isCreator={isCreator}
            isModer={isModer}
            status={status}
            recruitmentFinish={recruitmentFinish}
            caddyPrice={caddyPrice}
          />
          <OrderDetailedInfo
            order={order}
            caddyPrice={caddyPrice}
            isModer={isModer}
            status={status}
          />
          <OrderDetailedComments orderId={order.id} />
        </Grid.Column>

        <Grid.Column width={currentWidth > 700 ? 6 : 16}>
          <OrderDetailedSidebar
            order={order}
            isCreator={isCreator}
            isModer={isModer}
            approvedCaddys={approvedCaddys}
            recruitmentFinish={recruitmentFinish}
          />
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
};

export default OrderDetailedPage;

import React from 'react';
import { Container, Header, Segment } from 'semantic-ui-react';
import PhoneNumber from './PhoneNumber';

const GolfBoardsPage = () => {
  return (
    <Container text>
      <Segment.Group>
        <Segment>
          <Header sub>Старт продаж CaddyGo GolfBoard в России. Успейте получить скидку 15%</Header>
        </Segment>
        <Segment>
          <p>
            Все мы знаем стереотип о гольфе: «игра для пенсионеров, скучно, долго...». Но теперь
            данный вид спорта стал намного интересней и веселее, ведь теперь можно совместить череду
            хороших ударов с наслаждением сёрфинга по феервеям! Да, да - ощущение от нового гаджета
            Golfboard CaddyGo сравнимы с катанием на сноуборде по целине или, как поймать волну на
            сёрфинге...
          </p>
          <p>
            Теперь полноценный раунд в гольф 18 лунок на чемпионском поле может занимать всего пару
            часов. Можно сыграть быстро 18 лунок перед работой менее чем за 2 часа или проехать за
            час девяточку перед закатом солнца. При этом, вы совсем не ощутите усталость, а,
            наоборот, прилив энергии и хорошего настроения.
          </p>
          <p>
            Группа разработчиков во главе с Максимом Шокуном закончили двухлетнюю фазу тестирования
            и доработки гольфборда CaddyGo и готовы порадовать первых гольфбордистов уникальным
            предложением!
          </p>

          {/* <p>
            Многое было протестировано и доработано: - увеличен срок службы и емкость батареи -
            усилены детали рамы - усиленные крепежи и болты - усиленные пружины - использованы
            крепежи из нержавеющей стали - брызговики на передних и задних крыльях - эластичные
            крепления сумки для гольфа
          </p>
          <p>
            Все гольфборды проходят доводку и тестирование в России и передаются новому владельцу с
            гарантией 1 год.
          </p>
          <p>
            Ещё многое предстоит сделать: - светодиоды спереди и сзади - полный привод - карбоновые
            детали - алюминиевая рама - бордкомпьютер с параметрами для гольфа - дисковый тормоз{' '}
          </p>
          <p>
            Гольф на гольфборде - это модно, современно и весело. Лучше этого только гольф на
            гольфборде с друзьями!
          </p>
          <p></p>

          <p></p> */}
          <p>Спешите оказаться среди первых 10-ти заказчиках и получите скидку 15%!</p>
        </Segment>

        <Segment>
          <p>Более подробно о гольф-бордах:</p>
          <p>
            <a
              href='https://golf.ru/news/equipment/speshite_okazatsya_sredi_pervykh_10_ti_zakazchikakh_caddygo/'
              target='_blanc'
            >
              Golf.ru: «Старт продаж CaddyGo GolfBoard»
            </a>
          </p>
        </Segment>

        <Segment>
          <p>По вопросам приобретения можете обратиться к Максиму Шокуну:</p>
          <p>
            <PhoneNumber phoneNumber={process.env.REACT_APP_ADMIN_PHONE_NUMBER} />
          </p>
          <p>
            <a href='mailto:go@caddygo.ru' target='_blanc'>
              go@caddygo.ru
            </a>
          </p>
        </Segment>
      </Segment.Group>
    </Container>
  );
};

export default GolfBoardsPage;

import React from 'react';
import { Segment, Grid, Icon, Item } from 'semantic-ui-react';
import { golfClubs, caddyLevel, paymentStatus } from '../../../app/api/orderOptions';
import { format } from 'date-fns';
import ru from 'date-fns/locale/ru';
import PhoneNumber from '../../other/PhoneNumber';

const OrderDetailedInfo = ({ order, caddyPrice, status, isModer }) => {
  const currentGolfClub = order?.golfСourse
    ? golfClubs.find((elem) => elem.key === order.golfСourse)?.text
    : order.golfСourse;

  const currentPaymentStatus = order?.paymentStatus
    ? paymentStatus.find((elem) => elem.key === order.paymentStatus)?.text
    : order.paymentStatus;

  let requstedCaddyLevel;
  if (order?.caddyLevel && order.caddyLevel.length > 0) {
    requstedCaddyLevel = order.caddyLevel
      .map((level) => {
        return caddyLevel.find((elem) => elem?.key === level)?.text;
      })
      .join(', ');
  } else {
    requstedCaddyLevel = 'любой';
  }

  return (
    <Segment.Group>
      {order?.creatorPhoneNumber &&
        (isModer || (status === 'approved' && order?.paymentStatus === 'paid')) && (
          <Segment attached>
            <Grid verticalAlign='middle'>
              <Grid.Column width={1}>
                <Icon name='phone' size='large' color='teal' />
              </Grid.Column>
              <Grid.Column width={14} className='left-space'>
                <span>
                  {/* Телефон гольфиста:{' '} */}
                  <Item.Header as='h5'>
                    <PhoneNumber phoneNumber={order?.creatorPhoneNumber} />
                  </Item.Header>
                </span>
              </Grid.Column>
            </Grid>
          </Segment>
        )}

      <Segment attached>
        <Grid verticalAlign='middle'>
          <Grid.Column width={1}>
            <Icon name='calendar' size='large' color='teal' />
          </Grid.Column>
          <Grid.Column width={14} className='left-space'>
            <span>
              {format(order.date, 'd MMMM, eeee, H:mm ', {
                locale: ru,
              })}
            </span>
          </Grid.Column>
        </Grid>
      </Segment>

      <Segment attached>
        <Grid verticalAlign='middle'>
          <Grid.Column width={1}>
            <Icon name='marker' size='large' color='teal' />
          </Grid.Column>
          <Grid.Column width={14} className='left-space'>
            <span>Поле: {currentGolfClub}</span>
          </Grid.Column>
          {/* <Grid.Column width={4}>
            <Button color='teal' size='tiny' content='Show Map' />
          </Grid.Column> */}
        </Grid>
      </Segment>

      <Segment attached>
        <Grid verticalAlign='middle'>
          <Grid.Column width={1}>
            <Icon name='pin' size='large' color='teal' />
          </Grid.Column>
          <Grid.Column width={14} className='left-space'>
            <span>Место и время встречи: {order.meetingPoint || ''}</span>
          </Grid.Column>
          {/* <Grid.Column width={4}>
            <Button color='teal' size='tiny' content='Show Map' />
          </Grid.Column> */}
        </Grid>
      </Segment>

      <Segment attached>
        <Grid verticalAlign='middle'>
          <Grid.Column width={1}>
            <Icon name='user' size='large' color='teal' />
          </Grid.Column>
          <Grid.Column width={14} className='left-space'>
            <span>Требуется кедди: {order.caddysLimit} чел.</span>
          </Grid.Column>
        </Grid>
      </Segment>

      <Segment attached>
        <Grid verticalAlign='middle'>
          <Grid.Column width={1}>
            <Icon name='chart line' size='large' color='teal' />
          </Grid.Column>
          <Grid.Column width={14} className='left-space'>
            <span>Уровень кедди: {requstedCaddyLevel}</span>
          </Grid.Column>
        </Grid>
      </Segment>

      {order.pickupReady && (
        <Segment attached>
          <Grid verticalAlign='middle'>
            <Grid.Column width={1}>
              <Icon name='car' size='large' color='teal' />
            </Grid.Column>
            <Grid.Column width={14} className='left-space'>
              <span>Готов подвезти</span>
            </Grid.Column>
            {/* <Grid.Column width={4}>
            <Button color='teal' size='tiny' content='Show Map' />
          </Grid.Column> */}
          </Grid>
        </Segment>
      )}

      {order.soberDriverRequired && (
        <Segment attached>
          <Grid verticalAlign='middle'>
            <Grid.Column width={1}>
              <Icon name='vcard' size='large' color='teal' />
            </Grid.Column>
            <Grid.Column width={14} className='left-space'>
              <span>
                <u>Требуется трезвый водитель!</u>
              </span>
              <br />
              <span>Кедди должен иметь права и быть готов сесть за руль на обратном пути</span>
            </Grid.Column>
            {/* <Grid.Column width={4}>
            <Button color='teal' size='tiny' content='Show Map' />
          </Grid.Column> */}
          </Grid>
        </Segment>
      )}

      {order.description && (
        <Segment attached>
          <Grid>
            <Grid.Column width={1}>
              <Icon size='large' color='teal' name='info' />
            </Grid.Column>
            <Grid.Column width={14} className='left-space'>
              <p>{order.description}</p>
            </Grid.Column>
          </Grid>
        </Segment>
      )}

      <Segment attached>
        <Grid verticalAlign='middle'>
          <Grid.Column width={1}>
            <Icon name='ruble' size='large' color='teal' />
          </Grid.Column>
          <Grid.Column width={14} className='left-space'>
            <span>Сумма к оплате: {caddyPrice} руб.</span>
          </Grid.Column>
        </Grid>
      </Segment>

      <Segment attached>
        <Grid verticalAlign='middle'>
          <Grid.Column width={1}>
            <Icon name='money bill alternate outline' size='large' color='teal' />
          </Grid.Column>
          <Grid.Column width={14} className='left-space'>
            <span>Бронирование {currentPaymentStatus}</span>
          </Grid.Column>
        </Grid>
      </Segment>
    </Segment.Group>
  );
};

export default OrderDetailedInfo;

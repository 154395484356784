import { useField } from 'formik';
import React, { useState } from 'react';
import NumberInput from 'semantic-ui-react-numberinput';
import { FormField, Label } from 'semantic-ui-react';

const NumberButtonsInput = ({ label, ...props }) => {
  const [field, meta, helpers] = useField(props);
  const { setValue } = helpers;
  const [caddyCount, setCaddyCount] = useState(
    field?.value ? field.value + ' кедди' : '1 кедди'
  );
  // console.log(caddyCount);

  return (
    <FormField error={meta.touched && !!meta.error}>
      <label>{label}</label>
      <NumberInput
        {...field}
        {...props}
        value={caddyCount || 1}
        onChange={(val) => {
          if (!val) val = 1;
          setCaddyCount(val + ' кедди');
          setValue(val);
        }}
        onClick={(val) => setValue(val)}
      />
      {meta.touched && meta.error ? (
        <Label basic color='red'>
          {meta.error}
        </Label>
      ) : null}
    </FormField>
  );
};

export default NumberButtonsInput;

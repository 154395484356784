import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { toast } from 'react-toastify';
import { Segment } from 'semantic-ui-react';
import { addSignInEmailLinkToExistUser } from '../../app/firestore/firebaseService';

const EmailLinkAuthBind = () => {
  const history = useHistory();
  const link = window.location.href;
  const [isOtherBrowser, setIsOtherBrowser] = useState(false);

  useEffect(() => {
    let email = window.localStorage.getItem('emailForSignIn');
    if (email) {
      addSignInEmailLinkToExistUser(email, link)
        .then((usercred) => {
          toast.success('Email успешно привязан для входа!');
          console.log(usercred);
          history.push('/settings');
        })
        .catch((error) => {
          toast.error(error.message);
        });
    } else {
      setIsOtherBrowser(true);
      toast.error('Кажется, Вы перешли по ссылке с другого устройства или браузера.');
    }
    // console.log(email);
  }, [link, history]);

  return (
    <Segment textAlign='center' vertical>
      {isOtherBrowser && (
        <span>
          Кажется, Вы перешли по ссылке с другого устройства или браузера.
          <br />
          Откройте ссылку в том же браузере, где начали привязывать аккаунт
        </span>
      )}
    </Segment>
  );
};

export default EmailLinkAuthBind;

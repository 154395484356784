import { useField } from 'formik';
import React, { useState } from 'react';
import { FormField, Label } from 'semantic-ui-react';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import ru from 'react-phone-input-2/lang/ru.json';

const StyledPhoneInput = ({ label, initVal, ...props }) => {
  const [phone, setPhone] = useState(initVal);
  const [field, meta, helpers] = useField(props);
  const { setValue } = helpers;

  return (
    <FormField error={meta.touched && !!meta.error}>
      <label>{label}</label>
      <PhoneInput
        country={'ru'}
        regions={['europe', 'asia']}
        localization={ru}
        inputStyle={{ height: 43, width: '100%' }}
        containerStyle={{
          marginBottom: '30px',
        }}
        inputProps={{
          ...props,
          required: true,
          autoFocus: true,
          autoCorrect: 'off',
        }}
        value={phone}
        onChange={(phone) => {
          setPhone(phone);
          setValue(`+${phone}`);
        }}
      />
      {meta.touched && meta.error ? (
        <Label basic color='red'>
          {meta.error}
        </Label>
      ) : null}
    </FormField>
  );
};

export default StyledPhoneInput;

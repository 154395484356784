import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/database';
import 'firebase/auth';
import 'firebase/storage';

const firebaseConfig = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: 'caddygo.ru',
  projectId: 'caddygo-809b0',
  storageBucket: 'caddygo-809b0.appspot.com',
  messagingSenderId: '739033891715',
  appId: '1:739033891715:web:79285194044faa3246ecb6',
  measurementId: 'G-4NV7734MB5',
  databaseURL: 'https://caddygo-809b0-default-rtdb.europe-west1.firebasedatabase.app',
};

firebase.initializeApp(firebaseConfig);
firebase.firestore();
firebase.auth().languageCode = 'ru';

export default firebase;

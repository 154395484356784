import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Segment, Item, Label, Button, Icon, Header } from 'semantic-ui-react';
import { updateOrderCaddyStatus } from '../../../app/firestore/firestoreService';
import { format } from 'date-fns';
import { caddyLevel } from '../../../app/api/orderOptions';
import PhoneNumber from '../../other/PhoneNumber';

const OrderDetailedSidebar = ({ order, isCreator, isModer, approvedCaddys, recruitmentFinish }) => {
  const { caddys, caddysProfiles } = order;
  const [loading, setLoading] = useState(false);

  // const approvedCaddys = caddys.filter((caddy) => {
  //   return caddy.status === 'approved';
  // });
  // const recruitmentFinish = approvedCaddys.length === +order.caddysLimit;

  const handleApproveCaddy = async (caddyId) => {
    setLoading(true);
    const caddy = { id: caddyId };
    try {
      await updateOrderCaddyStatus(order, caddy, false, 'approved');
    } catch (error) {
      toast.error(error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleCancelCaddy = async (caddyId) => {
    setLoading(true);
    const caddy = { id: caddyId };
    try {
      await updateOrderCaddyStatus(order, caddy, false, 'responded');
    } catch (error) {
      toast.error(error.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Segment textAlign='center' style={{ border: 'none' }} attached='top' inverted color='teal'>
        <Header as='h5'>
          {caddys.lenght && caddys.lenght > 1 ? 'Откликнувшийся' : 'Откликнувшиеся'} кедди
        </Header>
      </Segment>
      <Segment attached>
        <Item.Group relaxed divided>
          {caddys.length > 0 &&
            caddys.map((caddy) => {
              const caddyProfile = caddysProfiles.find(
                (profile) => profile.caddyId === caddy.caddyId
              );

              // console.log(caddyProfile);

              const currentCaddyLevel = caddyProfile?.caddyLevel
                ? caddyLevel.find((elem) => elem.key === caddyProfile.caddyLevel)?.text
                : caddyProfile.caddyLevel;

              if (caddy.status === 'new' || caddy.status === 'declined') return null;

              return (
                <Item key={caddy.caddyId} style={{ position: 'relative' }}>
                  {caddy.status === 'approved' && (
                    <Label
                      style={{ position: 'absolute' }}
                      color='green'
                      ribbon='right'
                      content='Выбранный кедди'
                    />
                  )}
                  <Item.Image
                    as={Link}
                    to={`/profile/${caddy.caddyId}`}
                    size='tiny'
                    src={caddyProfile?.photoURL || '/assets/user.png'}
                  />
                  <Item.Content verticalAlign='middle' style={{ maxWidth: 240 }}>
                    <Item.Header as='h3'>
                      <Link to={`/profile/${caddy.caddyId}`}>{caddyProfile?.displayName}</Link>
                    </Item.Header>
                    <Item.Description>
                      {caddyProfile?.caddySince && (
                        <>
                          Кедди с {format(caddyProfile?.caddySince?.toDate(), 'yyyy')} года
                          <br />
                        </>
                      )}

                      {currentCaddyLevel && (
                        <>
                          Уровень: {currentCaddyLevel}
                          <br />
                        </>
                      )}

                      {caddyProfile?.caddyPrice && (
                        <>
                          Цена: {caddyProfile?.caddyPrice}
                          <br />
                        </>
                      )}

                      {caddyProfile?.achievements && (
                        <>
                          Достижения: {caddyProfile?.achievements}
                          <br />
                        </>
                      )}

                      {order.soberDriverRequired && caddyProfile?.driversLicense && (
                        <>
                          Есть водительское удостоверение («трезвый водитель»)
                          <br />
                        </>
                      )}

                      {(isCreator || isModer) &&
                        recruitmentFinish &&
                        order?.paymentStatus === 'paid' &&
                        caddy.status === 'approved' && (
                          <Item.Header as='h5'>
                            <PhoneNumber phoneNumber={caddyProfile?.phoneNumber} />
                          </Item.Header>
                        )}
                      {(isCreator || isModer) && !recruitmentFinish && caddy.status !== 'approved' && (
                        <Button
                          positive
                          loading={loading}
                          className='button-margin-top'
                          onClick={() => handleApproveCaddy(caddy.caddyId)}
                          // color='green'
                          // icon
                          // content=''
                          // labelPosition='left'
                        >
                          <Icon name='check' className='caddy-choice' />
                          Выбрать
                        </Button>
                      )}
                      {((isCreator && !recruitmentFinish) || isModer) &&
                        caddy.status === 'approved' && (
                          <Button
                            negative
                            loading={loading}
                            className='button-margin-top'
                            onClick={() => handleCancelCaddy(caddy.caddyId)}
                            // color='green'
                            // icon
                            // content=''
                            // labelPosition='left'
                          >
                            <Icon name='x' className='caddy-choice' />
                            Отменить выбор
                          </Button>
                        )}
                    </Item.Description>
                  </Item.Content>
                </Item>
              );
            })}
        </Item.Group>
      </Segment>
    </>
  );
};

export default OrderDetailedSidebar;

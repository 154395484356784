import React from 'react';
import { Route, useLocation } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { Container } from 'semantic-ui-react';
import OrderDashboard from '../../features/orders/orderDashboard/OrderDashboard';
import OrderDetailedPage from '../../features/orders/orderDetailed/OrderDetailedPage';
import OrderForm from '../../features/orders/orderForm/OrderForm';
import HomePage from '../../features/home/HomePage';
import NavBar from '../../features/nav/NavBar';
import ModalManager from '../common/modals/ModalManager';
import ErrorComponent from '../common/errors/ErrorComponent';
import { useSelector } from 'react-redux';
import LoadingComponent from './LoadingComponent';
import LoginPage from '../../features/auth/LoginPage';
// import EmailLoginForm from '../../features/auth/EmailLoginForm';
import RegistrationPage from '../../features/auth/RegistrationPage';
import ProfilePage from '../../features/profiles/profilePage/ProfilePage';
import BottomSpace from '../../features/nav/BottomSpace';
import NotificationsPage from '../../features/Notifications/NotificationsPage';
import PrivateRoute from './PrivateRoute';
import { getBuildDate } from '../common/util/util';
import clearCache from './clearCache';
import packageJson from '../../../package.json';
import SettingsPage from '../../features/profiles/profilePage/SettingsPage';
import MobileSidebarMenu from '../../features/nav/MobileSidebarMenu';
import FeedbackPage from '../../features/other/FeedbackPage';
import CaddySchoolPage from '../../features/other/CaddySchoolPage';
import TourCaddyGo from '../../features/other/TourCaddyGo';
import GolfBoardsPage from '../../features/other/GolfBoardsPage';
import CaddyListPage from '../../features/other/CaddyListPage';
import EmailLoginConfirm from '../../features/auth/EmailLoginConfirm';
import EmailLinkAuthBind from '../../features/auth/EmailLinkAuthBind';

console.log('Build date', getBuildDate(packageJson.buildDate));
const ClearCacheComponent = clearCache(App);

function cacheApp() {
  return <ClearCacheComponent />;
}

function App() {
  const { key } = useLocation();
  const { initialized } = useSelector((state) => state.async);

  if (!initialized) return <LoadingComponent content='Загрузка...' />;

  return (
    <>
      <ModalManager />
      <ToastContainer position='bottom-right' hideProgressBar />
      <Route exact path='/' component={HomePage} />
      <Route
        path={'/(.+)'}
        render={() => (
          <>
            <NavBar />
            <Container text className='main'>
              <Route exact path='/login' component={LoginPage} />
              <Route exact path='/email-login' component={EmailLoginConfirm} />
              <Route exact path='/email-bind' component={EmailLinkAuthBind} />
              <PrivateRoute exact path='/reg' component={RegistrationPage} />
              <PrivateRoute exact path='/orders' component={OrderDashboard} />
              <PrivateRoute exact path='/notifications' component={NotificationsPage} />
              {/* <Route exact path='/sandbox' component={Sandbox} /> */}
              <PrivateRoute path='/orders/:id' component={OrderDetailedPage} />
              <PrivateRoute path={['/new', '/manage/:id']} component={OrderForm} key={key} />
              <PrivateRoute exact path={['/profile', '/profile/:id']} component={ProfilePage} />
              <PrivateRoute exact path='/settings-menu' component={MobileSidebarMenu} />
              <PrivateRoute exact path='/settings' component={SettingsPage} />
              <PrivateRoute exact path='/feedback' component={FeedbackPage} />
              <PrivateRoute exact path='/caddy-school' component={CaddySchoolPage} />
              <PrivateRoute exact path='/tour' component={TourCaddyGo} />
              <PrivateRoute exact path='/golfboard' component={GolfBoardsPage} />
              <PrivateRoute exact path='/caddys' component={CaddyListPage} />
              <Route path='/error' component={ErrorComponent} />
            </Container>
            <BottomSpace />
          </>
        )}
      />
    </>
  );
}

export default cacheApp;

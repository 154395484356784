import { format } from 'date-fns';

export const delay = (ms) => {
  return new Promise((resolve) => setTimeout(resolve, ms));
};

export const getFileExtension = (filename) => {
  return filename.slice(((filename.lastIndexOf('.') - 1) >>> 0) + 2);
};

export const isNumber = (num) => {
  return typeof num === 'number' && !isNaN(num);
};

export const getBuildDate = (epoch) => {
  const date = new Date(epoch);
  console.log(date);
  // return fromUnixTime(epoch);
  return format(date, 'dd.MM.yyyy HH:mm');
};

export const formatPhoneNumber = (phone) => {
  if (phone && phone[0] == '8') phone = `+7${phone.substr(1)}`;
  if (phone && phone.length === 12) {
    phone = `${phone.substr(0, 2)} (${phone.substr(2, 3)}) ${phone.substr(5, 3)}-${phone.substr(
      8,
      2
    )}-${phone.substr(10, 2)}`;
  }
  return phone;
};

export const phoneRegExp =
  /^(\+?\d{0,4})?\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{2}\)?)\s?-?\s?(\(?\d{2}\)?)?$/;

export const createDataTree = (dataset) => {
  let dataTree = [];
  let hashtable = Object.create(null);

  dataset.forEach((e) => (hashtable[e.id] = { ...e, childNodes: [] }));

  dataset.forEach((e) => {
    if (e.parentId) hashtable[e.parentId]?.childNodes.push(hashtable[e.id]);
    else dataTree.push(hashtable[e.id]);
  });

  return dataTree;
};

import React from 'react';
import { Container, Header } from 'semantic-ui-react';
import RegisterForm from './RegisterForm';

const RegistrationPage = (props) => {
  let facebook =
    props?.location?.userRes?.additionalUserInfo?.providerId === 'facebook.com';
  // facebook = true;

  return (
    <Container textAlign='center' style={{ width: '350px' }}>
      <Header as='h1' className='dark-grey-text'>
        Добро пожаловать в CaddyGo!
      </Header>
      <Header as='h2' className='dark-grey-text'>
        Давайте познакомимся.
      </Header>
      <Header.Subheader as='h5' className='dark-grey-text'>
        {' '}
        {facebook
          ? 'Проверьте, правильно ли указано Ваше имя?'
          : 'Как Вас зовут?'}
      </Header.Subheader>
      {/* 
      {facebook && (
        <Header.Subheader as='h5' className='dark-grey-text'>
          А также укажите Ваш контактный телефон, чтобы кедди могли с Вами
          связаться:
        </Header.Subheader>
      )} */}

      <RegisterForm userRes={props?.location?.userRes} facebook={facebook} />
    </Container>
  );
};

export default RegistrationPage;

export const CREATE_ORDER = 'CREATE_ORDER';
export const UPDATE_ORDER = 'UPDATE_ORDER';
export const DELETE_ORDER = 'DELETE_ORDER';
export const FETCH_ORDERS = 'FETCH_ORDER';
export const CLEAR_ORDERS = 'CLEAR_ORDERS';
export const LISTEN_TO_SELECTED_ORDER = 'LISTEN_TO_SELECTED_ORDER';
export const LISTEN_TO_ORDER_COMMENTS = 'LISTEN_TO_ORDER_COMMENTS';
export const CLEAR_SELECTED_ORDER = 'CLEAR_SELECTED_ORDER';
export const CLEAR_COMMENTS = 'CLEAR_COMMENTS';
export const SET_TYPE_FILTER = 'SET_TYPE_FILTER';
export const SET_TIME_FILTER = 'SET_TIME_FILTER';
export const SET_CADDY_CHOICE_FILTER = 'SET_CADDY_CHOICE_FILTER';
export const SET_START_DATE = 'SET_START_DATE';
export const RETAIN_STATE = 'RETAIN_STATE';

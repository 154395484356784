import { combineReducers } from 'redux';
import authReducer from '../../features/auth/authReducer';
import orderReducer from '../../features/orders/orderReducer';
import profileReducer from '../../features/profiles/profileReducer';
import testReducer from '../../features/sandbox/testReducer';
import asyncReducer from '../async/asyncReducer';
import modalReducer from '../common/modals/modalReducer';

const rootReducer = combineReducers({
  test: testReducer,
  order: orderReducer,
  modals: modalReducer,
  auth: authReducer,
  async: asyncReducer,
  profile: profileReducer,
});

export default rootReducer;

import {
  CLEAR_COMMENTS,
  CLEAR_ORDERS,
  CLEAR_SELECTED_ORDER,
  CREATE_ORDER,
  DELETE_ORDER,
  FETCH_ORDERS,
  LISTEN_TO_ORDER_COMMENTS,
  LISTEN_TO_SELECTED_ORDER,
  RETAIN_STATE,
  SET_CADDY_CHOICE_FILTER,
  SET_START_DATE,
  SET_TIME_FILTER,
  SET_TYPE_FILTER,
  UPDATE_ORDER,
} from './orderConstants';

const initialState = {
  orders: [],
  comments: [],
  moreOrders: true,
  selectedOrder: null,
  lastVisible: null,
  typeFilter: null,
  timeFilter: 'upcoming',
  caddyChoiceFilter: 'all',
  startDate: new Date(),
  retainState: false,
};

const orderReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case CREATE_ORDER:
      return {
        ...state,
        orders: [...state.orders, payload],
      };

    case UPDATE_ORDER:
      return {
        ...state,
        orders: [...state.orders.filter((order) => order.id !== payload.id), payload],
      };

    case DELETE_ORDER:
      return {
        ...state,
        orders: [...state.orders.filter((order) => order.id !== payload)],
      };

    case FETCH_ORDERS:
      return {
        ...state,
        orders: [...state.orders, ...payload.orders],
        moreOrders: payload.moreOrders,
        lastVisible: payload.lastVisible,
      };

    case CLEAR_ORDERS:
      return {
        ...state,
        orders: [],
        moreOrders: true,
        lastVisible: null,
        retainState: false,
      };

    case LISTEN_TO_SELECTED_ORDER:
      return {
        ...state,
        selectedOrder: payload,
      };

    case LISTEN_TO_ORDER_COMMENTS:
      return {
        ...state,
        comments: payload,
      };

    case CLEAR_SELECTED_ORDER:
      return {
        ...state,
        selectedOrder: null,
      };

    case CLEAR_COMMENTS:
      return {
        ...state,
        comments: [],
      };

    case SET_TYPE_FILTER:
      return {
        ...state,
        retainState: false,
        moreOrders: true,
        typeFilter: payload,
      };

    case SET_TIME_FILTER:
      return {
        ...state,
        retainState: false,
        moreOrders: true,
        timeFilter: payload,
      };

    case SET_CADDY_CHOICE_FILTER:
      return {
        ...state,
        retainState: false,
        moreOrders: true,
        caddyChoiceFilter: payload,
      };

    case SET_START_DATE:
      return {
        ...state,
        retainState: false,
        moreOrders: true,
        startDate: payload,
      };

    case RETAIN_STATE:
      return {
        ...state,
        retainState: true,
      };

    default:
      return state;
  }
};

export default orderReducer;

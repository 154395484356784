import React from 'react';
import { Tab } from 'semantic-ui-react';
import MainInfoTab from './MainInfoTab';
import OrdersTab from './OrdersTab';
import PhotosTab from './PhotosTab';

const ProfileContent = ({ profile, isCurrentUser, isModer }) => {
  const panes = [
    {
      menuItem: { key: 'profile', icon: 'user', content: 'Профиль' },
      render: () => (
        <MainInfoTab
          profile={profile}
          isCurrentUser={isCurrentUser}
          isModer={isModer}
        />
      ),
    },
    {
      menuItem: { key: 'photo', icon: 'photo', content: 'Фото' },
      render: () => (
        <PhotosTab profile={profile} isCurrentUser={isCurrentUser} />
      ),
    },
    {
      menuItem: { key: 'orders', icon: 'list ul', content: 'Бронирования' },
      render: () => <OrdersTab profile={profile} />,
    },
  ];
  if (!(isCurrentUser || isModer)) panes.pop();

  return (
    <Tab
      menu={{
        fluid: true,
        secondary: true,
        style: { justifyContent: 'center' },
      }}
      panes={panes}
      // grid={{ paneWidth: 16 }}
    />
  );
};

export default ProfileContent;

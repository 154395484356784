import { Formik, Form } from 'formik';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, Redirect } from 'react-router-dom';
import { Button, Confirm, Header, Segment } from 'semantic-ui-react';
import { clearOrders, clearSelectedOrder, listenToSelectedOrder } from '../orderActions';
import * as Yup from 'yup';
import TextInput from '../../../app/common/form/TextInput';
import TextArea from '../../../app/common/form/TextArea';
import SelectInput from '../../../app/common/form/SelectInput';
import NumberButtonsInput from '../../../app/common/form/NumberButtonsInput';
import { caddyGender, caddyLevel, golfClubs } from '../../../app/api/orderOptions';
import DateInput from '../../../app/common/form/DateInput';
import {
  addOrderToFirestore,
  cancelOrderToggle,
  listenToOrderFromFirestore,
  updateOrderInFirestore,
  deleteOrderInFirestore,
} from '../../../app/firestore/firestoreService';
import useFirestoreDoc from '../../../app/hooks/useFirestoreDoc';
import LoadingComponent from '../../../app/layout/LoadingComponent';
import { toast } from 'react-toastify';
import MultiSelectInput from '../../../app/common/form/MultiSelectInput';
import { addDays, setHours, setMinutes } from 'date-fns';
import CheckboxInput from '../../../app/common/form/CheckboxInput';

const OrderForm = ({ match, history, location }) => {
  const dispatch = useDispatch();
  const [loadingCancel, setLoadingCancel] = useState(false);
  const [cancelConfirmOpen, setCancelConfirmOpen] = useState(false);
  const [deleteConfirmOpen, setDeleteConfirmOpen] = useState(false);

  let { selectedOrder } = useSelector((state) => state.order);
  const { currentUserProfile } = useSelector((state) => state.profile);
  const { isModer } = currentUserProfile || false;
  const { loading, error } = useSelector((state) => state.async);

  useEffect(() => {
    if (location.pathname !== '/new') return;
    dispatch(clearSelectedOrder());

    document
      .getElementsByClassName('number-input')[0]
      .childNodes[0].childNodes[1].childNodes[0].setAttribute('inputMode', 'none');
    document
      .getElementsByClassName('react-datepicker__input-container')[0]
      .childNodes[0].setAttribute('inputMode', 'none');
  }, [dispatch, location.pathname]);

  const initialValues = selectedOrder ?? {
    caddyGender: 'any',
    caddyLevel: ['new', 'middle', 'pro'],
    description: '',
    meetingPoint: '',
    // city: '',
    golfСourse: '',
    date: '',
    pickupReady: false,
    soberDriverRequired: false,
    caddysLimit: '1',
    creatorEmail: currentUserProfile.email || null,
    creatorPhoneNumber: currentUserProfile.phoneNumber || null,
  };

  const validationSchema = Yup.object({
    caddyGender: Yup.string().required('Укажите, пожалуйста, пол кедди'),
    caddysLimit: Yup.number(),
    // .required('Укажите, пожалуйста, количество кедди'),
    caddyLevel: Yup.array()
      .of(Yup.string())
      .required('Укажите, пожалуйста, требуемый уровень кедди'),
    description: Yup.string(),
    meetingPoint: Yup.string(),
    // city: Yup.string().required('Укажите, пожалуйста, город'),
    golfСourse: Yup.string().required('Укажите, пожалуйста, гольф поле, на котором будет игра'),
    date: Yup.string().required('Укажите, пожалуйста, дату игры'),
    pickupReady: Yup.boolean(),
    soberDriverRequired: Yup.boolean(),
  });

  const handleCancelToggle = async (order) => {
    setCancelConfirmOpen(false);
    setLoadingCancel(true);

    try {
      await cancelOrderToggle(order);
      await dispatch(clearOrders());
      await setLoadingCancel(false);
      toast.success('Изменения сохранены', {
        position: 'top-right',
      });
      history.push('/orders');
    } catch (error) {
      setLoadingCancel(true);
      toast.error(error.message);
    }
  };

  const handleDeleteToggle = async (order) => {
    setDeleteConfirmOpen(false);
    setLoadingCancel(true);

    try {
      await deleteOrderInFirestore(order.id);
      await dispatch(clearOrders());
      await setLoadingCancel(false);
      toast.success('Заявка удалена', {
        position: 'top-right',
      });
      history.push('/orders');
    } catch (error) {
      setLoadingCancel(true);
      toast.error(error.message);
    }
  };

  useFirestoreDoc({
    shouldExecute: match.params.id !== selectedOrder?.id && location.pathname !== '/new',
    query: () => listenToOrderFromFirestore(match.params.id),
    data: (order) => dispatch(listenToSelectedOrder(order)),
    deps: [match.params.id, dispatch],
  });

  if (loading) return <LoadingComponent content='Loading' />;
  if (error) return <Redirect to='/error' />;

  return (
    <Segment clearing>
      <Formik
        enableReinitialize
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={async (values, { setSubmitting }) => {
          // console.log(values);
          try {
            if (selectedOrder) {
              await updateOrderInFirestore(values);
              toast.success('Изменения сохранены', {
                position: 'top-right',
              });
              history.push(`/orders/${values.id}`);
            } else {
              dispatch(clearOrders());
              const orderRef = await addOrderToFirestore(values);
              const order = await orderRef.get();
              toast.success('Заявка успешно создана', {
                position: 'top-right',
              });
              history.push(`/orders/${order.id}`);
            }
          } catch (error) {
            toast.error(error.message);
            // console.log(error);
            setSubmitting(false);
          }
        }}
      >
        {({ isSubmitting, dirty, isValid }) => {
          return (
            <Form className='ui form'>
              <Header
                sub
                color='teal'
                icon='user'
                content='Кедди'
                style={{ marginBottom: '1.5em' }}
              />
              <span>
                {/* Количество требуемых кедди:{' '} */}
                <NumberButtonsInput
                  name='caddysLimit'
                  buttonPlacement='leftAndRight'
                  placeholder='Количество требуемых кедди'
                  allowEmptyValue={true}
                  minValue={1}
                  maxValue={99}
                  defaultValue={1}
                  stepAmount={1}
                  // readOnly
                  // value={caddyCount}
                  className='number-input'
                />
              </span>
              <span>Уровень кедди:</span>
              <MultiSelectInput
                name='caddyLevel'
                placeholder='Уровень кедди (по-умолчанию — любой)'
                closeOnChange
                options={caddyLevel}
              />

              <span>Пол кедди:</span>
              <SelectInput name='caddyGender' placeholder='Пол кедди' options={caddyGender} />

              <CheckboxInput name='pickupReady' label='Готов подвезти кедди' />
              <CheckboxInput name='soberDriverRequired' label='Требуется «трезвый водитель»' />

              <Header
                sub
                color='teal'
                icon='list ul'
                content='Детали игры'
                style={{ marginBottom: '1.5em' }}
              />
              {/* <TextInput name='Город' placeholder='Город' /> */}
              <SelectInput
                name='golfСourse'
                placeholder='Выберете гольф-клуб или начните вводить название'
                search
                options={golfClubs}
              />
              <TextInput
                name='meetingPoint'
                placeholder='Место и время встречи (напр. «Драйвинг-рейндж в 9:00»)'
              />
              <DateInput
                name='date'
                placeholderText='Дата и время игры'
                timeFormat='HH:mm'
                showTimeSelect
                autoComplete='off'
                timeCaption='Время'
                timeIntervals={5}
                minDate={addDays(new Date(), 1)}
                // readOnly
                // inputMode='none'
                // keyboardType={'none'}
                // editable={false}
                minTime={setHours(setMinutes(new Date(), 0), 7)}
                maxTime={setHours(setMinutes(new Date(), 0), 20)}
                dateFormat='d MMMM HH:mm'
                locale='ru'
              />
              <TextArea name='description' placeholder='Комментарий' rows={3} />
              {selectedOrder && (
                <>
                  <Button
                    loading={loadingCancel}
                    type='button'
                    floated='left'
                    color={selectedOrder.isCancelled ? 'green' : 'grey'}
                    content={selectedOrder.isCancelled ? 'Восстановить заявку' : 'Отозвать заявку'}
                    onClick={() => setCancelConfirmOpen(true)}
                  />

                  {isModer && (
                    <Button
                      loading={loadingCancel}
                      type='button'
                      floated='left'
                      color='red'
                      content='Удалить заявку'
                      onClick={() => setDeleteConfirmOpen(true)}
                    />
                  )}
                </>
              )}
              <Button
                loading={isSubmitting}
                disabled={!isValid || !dirty || isSubmitting}
                type='submit'
                floated='right'
                positive
                content='Отправить'
              />
              <Button
                disabled={isSubmitting}
                as={Link}
                to={selectedOrder ? `/orders/${selectedOrder.id}` : `/orders/`}
                // type='submit'
                floated='right'
                content='Назад'
              />
            </Form>
          );
        }}
      </Formik>
      <Confirm
        content={
          selectedOrder?.isCancelled
            ? 'Сделать данную заявку вновь активной?'
            : 'Вы уверены, что хотите отозвать заявку?'
        }
        cancelButton='Отмена'
        confirmButton='Подтвердить'
        open={cancelConfirmOpen}
        onCancel={() => setCancelConfirmOpen(false)}
        onConfirm={() => handleCancelToggle(selectedOrder)}
      />
      <Confirm
        content='Вы уверены, что хотите удалить эту заявку?'
        cancelButton='Отмена'
        confirmButton='Подтвердить'
        open={deleteConfirmOpen}
        onCancel={() => setDeleteConfirmOpen(false)}
        onConfirm={() => handleDeleteToggle(selectedOrder)}
      />
    </Segment>
  );
};

export default OrderForm;

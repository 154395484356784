import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Button, Header, Segment } from 'semantic-ui-react';

const ErrorComponent = () => {
  const { error } = useSelector((state) => state.async);

  return (
    <Segment placeholder>
      <Header
        textAlign='center'
        content={error?.message || 'Кажется, нет никаких ошибок...'}
      />
      <Button
        as={Link}
        to='/orders'
        primary
        style={{ marginTop: 20 }}
        content='Назад к заявкам'
      />
    </Segment>
  );
};

export default ErrorComponent;

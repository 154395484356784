import React, { useEffect } from 'react';
import { Feed, Header, Item, Segment } from 'semantic-ui-react';
import { useWindowResize } from 'react-resize-tracker';
import { useDispatch, useSelector } from 'react-redux';
import {
  getUserNotificationsRef,
  firebaseObjectToArray,
  markAsReadUserNotifications,
  markAsReadAllUserNotification,
} from '../../app/firestore/firebaseService';
import { listenToUserNotifications } from '../profiles/profileActions.js';
import NotificationsListItem from './NotificationsListItem';
import { Link } from 'react-router-dom';

const NotificationsList = () => {
  const [currentWidth] = useWindowResize();
  const dispatch = useDispatch();
  const { authenticated } = useSelector((state) => state.auth);
  const { notifications, notificationsCount } = useSelector(
    (state) => state.profile
  );

  // console.log(notifications);

  useEffect(() => {
    if (!authenticated) return;
    getUserNotificationsRef().on('value', (snapshot) => {
      if (!snapshot.exists()) return;
      const notifications = firebaseObjectToArray(snapshot.val()).reverse();
      dispatch(listenToUserNotifications(notifications));
      markAsReadUserNotifications(snapshot);
    });
    return () => {
      getUserNotificationsRef().off();
    };
  }, [dispatch, authenticated]);

  return (
    <>
      {currentWidth > 700 && (
        <Header attached color='teal' icon='newspaper' content='Оповещения' />
      )}
      <Segment attached='bottom'>
        {notificationsCount > 0 && (
          <Item>
            <Link to='#' onClick={() => markAsReadAllUserNotification()}>
              Отметить все как прочитанное
            </Link>
          </Item>
        )}
        <Feed>
          {notifications.map((notification) => (
            <NotificationsListItem
              notification={notification}
              key={notification.id}
            />
          ))}
        </Feed>
      </Segment>
    </>
  );
};

export default NotificationsList;

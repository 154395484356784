import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { toast } from 'react-toastify';
import { Container, Form, Segment } from 'semantic-ui-react';
import { checkSignInEmailLink, signInWithEmailLink } from '../../app/firestore/firebaseService';

const EmailLoginConfirm = () => {
  const history = useHistory();
  const [emailFound, setEmailFound] = useState(false);
  const [emailFromField, setEmailFromField] = useState('');
  const link = window.location.href;

  useEffect(() => {
    if (checkSignInEmailLink(link)) {
      let email = window.localStorage.getItem('emailForSignIn');
      if (email) {
        setEmailFound(true);
      } else {
        email = emailFromField;
      }
      // console.log(email);

      if (email && emailFound) {
        signInWithEmailLink(email, link)
          .then((res) => {
            window.localStorage.removeItem('emailForSignIn');
            console.log(res);

            if (res?.additionalUserInfo?.isNewUser) {
              history.push({
                pathname: '/reg',
                userRes: res,
              });
            } else {
              history.push('/orders');
            }
          })
          .catch((error) => {
            toast.error(error.message);
            setEmailFound(false);
          });
      }
    }
  }, [link, emailFromField, emailFound, history]);

  return (
    <Segment textAlign='center' vertical>
      <Container style={{ width: '350px' }}>
        <Form className='ui form' onSubmit={() => setEmailFound(true)}>
          <label>
            Кажется, Вы перешли по ссылке с другого браузера или устройства.
            <br />
            <br />
            Чтобы убедиться, что это точно Вы, введите, пожалуйста, повторно Ваш email:
            <br />
          </label>
          <br />
          <Form.Input
            placeholder='Email, который Вы указали ранее'
            name='email'
            value={emailFromField}
            onChange={(e) => {
              setEmailFromField(e.target.value);
            }}
          />
          <Form.Button content='Подтвердить' fluid size='large' color='teal' />
        </Form>
      </Container>
    </Segment>
  );
};

export default EmailLoginConfirm;

import React, { useState, useEffect } from 'react';
import packageJson from '../../../package.json';
import compareAsc from 'date-fns/compareAsc';

const buildDateGreaterThan = (latestDate, currentDate) => {
  if (compareAsc(latestDate, currentDate) === 1) {
    return true;
  } else {
    return false;
  }
};

const withClearCache = (Component) => {
  function ClearCacheComponent(props) {
    const [isLatestBuildDate, setIsLatestBuildDate] = useState(false);

    useEffect(() => {
      fetch('/meta.json')
        .then((response) => response.json())
        .then((meta) => {
          const latestVersionDate = meta.buildDate;
          const currentVersionDate = packageJson.buildDate;
          // console.log('latestVersionDate', latestVersionDate);
          // console.log('currentVersionDate', currentVersionDate);

          const shouldForceRefresh = buildDateGreaterThan(
            latestVersionDate,
            currentVersionDate
          );
          if (shouldForceRefresh) {
            console.log('reloading cache');
            setIsLatestBuildDate(false);
            refreshCacheAndReload();
          } else {
            console.log('cache is up to date');
            setIsLatestBuildDate(true);
          }
        });
    }, []);

    const refreshCacheAndReload = () => {
      if (caches) {
        // Service worker cache should be cleared with caches.delete()
        caches.keys().then((names) => {
          for (const name of names) {
            caches.delete(name);
          }
        });
      }
      // delete browser cache and hard reload
      window.location.reload();
    };

    return (
      <React.Fragment>
        {isLatestBuildDate ? <Component {...props} /> : null}{' '}
      </React.Fragment>
    );
  }

  return ClearCacheComponent;
};

export default withClearCache;

import { Form, Formik } from 'formik';
import React from 'react';
import * as Yup from 'yup';
import TextInput from '../../app/common/form/TextInput';
import { Button, Container, Header, Label, Segment } from 'semantic-ui-react';
import { useDispatch } from 'react-redux';
import { registerInFirebase } from '../../app/firestore/firebaseService';
import { useHistory } from 'react-router';
import { signInUser } from './authActions';
import StyledPhoneInput from '../../app/common/form/StyledPhoneInput';
import SelectInput from '../../app/common/form/SelectInput';
import { phoneRegExp } from '../../app/common/util/util';

const RegisterForm = ({ userRes, facebook }) => {
  const dispatch = useDispatch();
  const history = useHistory();

  if (!userRes?.additionalUserInfo?.isNewUser) history.push('/orders');
  // console.log(userRes);

  const phoneInitialValue = facebook ? { phone: '' } : null;
  const phoneInput = facebook
    ? {
        phone: Yup.string()
          .matches(phoneRegExp, 'Проверьте корректность введённого номера')
          .required('Необходимо указать номер телефона'),
      }
    : null;

  return (
    <Segment textAlign='center' vertical>
      <Container style={{ width: '350px' }}>
        <Formik
          initialValues={{
            ...phoneInitialValue,
            firstName: userRes?.user?.firstName || '',
            lastName: userRes?.user?.lastName || '',
            gender: userRes?.user?.gender || '',
            email:
              userRes?.user?.email ||
              userRes?.user?.customEmail ||
              userRes?.additionalUserInfo?.profile?.email ||
              '',
            // password: '',
          }}
          validationSchema={Yup.object({
            ...phoneInput,
            firstName: Yup.string().required('Пожалуйста, укажите Ваше имя'),
            lastName: Yup.string(),
            email: Yup.string().email(),
            // password: Yup.string().required(),
          })}
          onSubmit={async (values, { setSubmitting, setErrors }) => {
            try {
              // console.log(values);
              await registerInFirebase(userRes, values);
              await dispatch(signInUser(userRes?.user));
              setSubmitting(false);
              history.push(`/profile/${userRes?.user?.uid}`);
            } catch (error) {
              setErrors({ auth: error.message });
              setSubmitting(false);
            }
          }}
        >
          {({ isSubmitting, isValid, dirty, errors }) => (
            <Form className='ui form'>
              <TextInput name='firstName' placeholder='Имя' />
              <TextInput name='lastName' placeholder='Фамилия' />
              <SelectInput
                name='gender'
                placeholder='Пол'
                options={[
                  { key: 'male', text: 'Мужской', value: 'male' },
                  { key: 'female', text: 'Женский', value: 'female' },
                ]}
              />
              {facebook && (
                <>
                  <Header.Subheader as='h5' className='dark-grey-text'>
                    Укажите Ваш контактный телефон, чтобы кедди могли с Вами связаться:
                  </Header.Subheader>
                  <StyledPhoneInput
                    name='phone'
                    placeholder='+7 (912) 345-67-89'
                    disabled={isSubmitting}
                    style={{ paddingLeft: 48 }}
                  />
                </>
              )}
              <Header.Subheader as='h5' className='dark-grey-text'>
                Укажите Email, если хотите получать уведомления о статусах (например, отклики кедди
                на Вашу заявку)
              </Header.Subheader>
              <TextInput name='email' placeholder='Email' />

              {/* <TextInput name='password' placeholder='Password' type='password' /> */}

              {errors.auth && (
                <Label basic color='red' style={{ marginBottom: 10 }} content={errors.auth} />
              )}
              <Button
                loading={isSubmitting}
                disabled={!isValid || !dirty || isSubmitting}
                type='submit'
                fluid
                size='large'
                color='teal'
                content='Сохранить'
              />
            </Form>
          )}
        </Formik>
      </Container>
    </Segment>
  );
};

export default RegisterForm;

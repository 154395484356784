import React from 'react';
import { Link } from 'react-router-dom';
import { Feed } from 'semantic-ui-react';
import { golfClubs } from '../../../src/app/api/orderOptions';
import { format, formatDistance } from 'date-fns';
import ru from 'date-fns/locale/ru';

const NotificationsListItem = ({ notification }) => {
  // console.log(notification);
  if (!notification || !notification.code) return null;
  let summary;
  const currentGolfClub = notification?.golfСourse
    ? golfClubs.find((elem) => elem.key === notification.golfСourse)?.text
    : false;

  // console.log(notification.code);

  switch (notification.code) {
    case 'responded':
      summary = (
        <>
          <Link to={`/profile/${notification.userUid}`}>{notification.displayName}</Link>{' '}
          откликнулся(-ась) на Ваше{' '}
          <Link to={`/orders/${notification.orderId}`}>
            бронирование кедди в {currentGolfClub}
            {', '}
            {format(notification.orderDate, 'd MMMM HH:mm', {
              locale: ru,
            })}
          </Link>
        </>
      );
      break;

    case 'declined':
      summary = (
        <>
          <Link to={`/profile/${notification.userUid}`}>{notification.displayName}</Link>{' '}
          отказался(-ась) от Вашего{' '}
          <Link to={`/orders/${notification.orderId}`}>
            бронирования кедди в {currentGolfClub}
            {', '}
            {format(notification.orderDate, 'd MMMM HH:mm', {
              locale: ru,
            })}
          </Link>
        </>
      );
      break;

    case 'approved':
      summary = (
        <>
          <Link to={`/profile/${notification.userUid}`}>{notification.displayName}</Link> выбрал(а)
          Вас как кедди в{' '}
          <Link to={`/orders/${notification.orderId}`}>
            {currentGolfClub}
            {', '}
            {format(notification.orderDate, 'd MMMM HH:mm', {
              locale: ru,
            })}
          </Link>
        </>
      );
      break;

    case 'newOrder':
      summary = (
        <>
          <Link to={`/profile/${notification.userUid}`}>{notification.displayName}</Link> создал(а)
          новое{' '}
          <Link to={`/orders/${notification.orderId}`}>
            бронирование кедди в {currentGolfClub}
            {', '}
            {format(notification.orderDate, 'd MMMM HH:mm', {
              locale: ru,
            })}
          </Link>
        </>
      );
      break;

    case 'orderCancelled':
      summary = (
        <>
          <Link to={`/profile/${notification.userUid}`}>{notification.displayName}</Link> отменил(а)
          свое{' '}
          <Link to={`/orders/${notification.orderId}`}>
            бронирование кедди в {currentGolfClub}
            {', '}
            {format(notification.orderDate, 'd MMMM HH:mm', {
              locale: ru,
            })}
          </Link>
        </>
      );
      break;

    case 'orderRestored':
      summary = (
        <>
          <Link to={`/profile/${notification.userUid}`}>{notification.displayName}</Link>{' '}
          восстановил(а) ранее отмененное{' '}
          <Link to={`/orders/${notification.orderId}`}>
            бронирование кедди в {currentGolfClub}
            {', '}
            {format(notification.orderDate, 'd MMMM HH:mm', {
              locale: ru,
            })}
          </Link>
        </>
      );
      break;

    case 'orderDetailsChanged':
      summary = (
        <>
          <Link to={`/profile/${notification.userUid}`}>{notification.displayName}</Link> изменил(а)
          некоторые данные в{' '}
          <Link to={`/orders/${notification.orderId}`}>
            заявке в {currentGolfClub}
            {', '}
            {format(notification.orderDate, 'd MMMM HH:mm', {
              locale: ru,
            })}
          </Link>
        </>
      );
      break;

    case 'paymentSent':
      summary = (
        <>
          <Link to={`/profile/${notification.userUid}`}>{notification.displayName}</Link> оплатил(а){' '}
          <Link to={`/orders/${notification.orderId}`}>
            бронирование в {currentGolfClub}
            {', '}
            {format(notification.orderDate, 'd MMMM HH:mm', {
              locale: ru,
            })}
            .{' '}
          </Link>
          Проверьте и подтвердите платеж.
        </>
      );
      break;

    case 'paymentReceived':
      summary = (
        <>
          <Link to={`/profile/${notification.userUid}`}>{notification.displayName}</Link> подтвердил
          и оплатил(-а){' '}
          <Link to={`/orders/${notification.orderId}`}>
            бронирование в {currentGolfClub}
            {', '}
            {format(notification.orderDate, 'd MMMM HH:mm', {
              locale: ru,
            })}
            .{' '}
          </Link>
          <br />
          Вас выбрали кедди. Свяжитесь с гольфистом для уточнения деталей. Телефон гольфиста
          доступен на <Link to={`/orders/${notification.orderId}`}>странице заявки</Link>
        </>
      );
      break;

    default:
      break;
  }

  return (
    <Feed.Event>
      <Feed.Label
        image={notification.photoURL || '/assets/user.png'}
        as={Link}
        to={`/profile/${notification.userUid}`}
      />
      <Feed.Content>
        <Feed.Summary>{summary}</Feed.Summary>
        <Feed.Date>
          {formatDistance(
            new Date(notification.notificationDate || new Date()),
            new Date(),
            {
              locale: ru,
            },
            { addSuffix: true }
          )}{' '}
          назад
        </Feed.Date>
      </Feed.Content>
    </Feed.Event>
  );
};

export default NotificationsListItem;

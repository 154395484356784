import React from 'react';
import { Link } from 'react-router-dom';
import { Image, List } from 'semantic-ui-react';

const OrderListCaddy = ({ caddy }) => {
  return (
    <List.Item as={Link} to={`/profile/${caddy?.caddyId}`}>
      <Image
        size='mini'
        circular
        className='caddy-list'
        src={caddy?.photoURL || '/assets/user.png'}
      />
    </List.Item>
  );
};

export default OrderListCaddy;

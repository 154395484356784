import {
  LISTEN_TO_CADDYS_PROFILES,
  LISTEN_TO_CURRENT_USER_PROFILE,
  LISTEN_TO_SELECTED_USER_PROFILE,
  LISTEN_TO_USER_NOTIFICATIONS,
  LISTEN_TO_USER_ORDERS,
  LISTEN_TO_USER_PHOTOS,
  LISTEN_TO_USER_UNREAD_NOTIFICATIONS_COUNT,
} from './profileConstants';

export const listenToCurrentUserProfile = (profile) => {
  return {
    type: LISTEN_TO_CURRENT_USER_PROFILE,
    payload: profile,
  };
};

export const listenToSelectedUserProfile = (profile) => {
  return {
    type: LISTEN_TO_SELECTED_USER_PROFILE,
    payload: profile,
  };
};

export const listenToUserPhotos = (photos) => {
  return {
    type: LISTEN_TO_USER_PHOTOS,
    payload: photos,
  };
};

export const listenToUserOrders = (orders) => {
  return {
    type: LISTEN_TO_USER_ORDERS,
    payload: orders,
  };
};

export const listenToUserNotifications = (notifications) => {
  return {
    type: LISTEN_TO_USER_NOTIFICATIONS,
    payload: notifications,
  };
};

export const listenToUserUnreadNotificationsCount = (notificationsCount) => {
  return {
    type: LISTEN_TO_USER_UNREAD_NOTIFICATIONS_COUNT,
    payload: notificationsCount,
  };
};

export const listenToCaddysProfiles = (caddys) => {
  return {
    type: LISTEN_TO_CADDYS_PROFILES,
    payload: caddys,
  };
};

import {
  LISTEN_TO_CADDYS_PROFILES,
  LISTEN_TO_CURRENT_USER_PROFILE,
  LISTEN_TO_SELECTED_USER_PROFILE,
  LISTEN_TO_USER_NOTIFICATIONS,
  LISTEN_TO_USER_ORDERS,
  LISTEN_TO_USER_PHOTOS,
  LISTEN_TO_USER_UNREAD_NOTIFICATIONS_COUNT,
} from './profileConstants';

const initialState = {
  currentUserProfile: null,
  selectedUserProfile: null,
  photos: [],
  profileOrders: [],
  notifications: [],
  notificationsCount: 0,
  caddys: [],
};

const profileReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case LISTEN_TO_CURRENT_USER_PROFILE:
      return {
        ...state,
        currentUserProfile: payload,
      };

    case LISTEN_TO_SELECTED_USER_PROFILE:
      return {
        ...state,
        selectedUserProfile: payload,
      };

    case LISTEN_TO_USER_PHOTOS:
      return {
        ...state,
        photos: payload,
      };

    case LISTEN_TO_USER_ORDERS:
      return {
        ...state,
        profileOrders: payload,
      };

    case LISTEN_TO_USER_NOTIFICATIONS:
      return {
        ...state,
        notifications: payload,
      };

    case LISTEN_TO_USER_UNREAD_NOTIFICATIONS_COUNT:
      return {
        ...state,
        notificationsCount: payload,
      };

    case LISTEN_TO_CADDYS_PROFILES:
      return {
        ...state,
        caddys: payload,
      };

    default: {
      return state;
    }
  }
};

export default profileReducer;

import React from 'react';
import { Container, Header, Segment } from 'semantic-ui-react';

const TourCaddyGo = () => {
  return (
    <Container text>
      <Segment.Group>
        <Segment>
          <Header sub>Первый в истории гольфа турнир с призовыми свыше 500£!</Header>
        </Segment>
        <Segment>
          <p>
            В России каждый год (и нынешний не исключение) проводится добрая сотня турниров среди
            любителей. А уж в мире счет идет на сотни тысяч, я думаю. Большая их часть играется в
            формате Стейблфорд. И вот проект CaddyGo объявляет еще один такой турнир. Вроде все
            довольно обыденно. Поле хорошее, состав участников наверняка будет приятным, программу
            перед началом, в процессе и, главное, после игры обещают насыщенную. В общем будет еще
            один спортивный день, переходящий в приятный вечер в кругу друзей.
          </p>
          <p>
            Но все совсем не так просто! Участники этого турнира смогут смело рассказывать своим
            детям, внукам и, возможно, и правнукам, что они принимали участие в первом турнире
            такого рода за всю историю гольфа! Причем не только в России, но и в мире! Такого
            турнира не было нигде и никогда за всю 600-летнюю историю нашей любимой игры! Первый
            случится 17 июля в России на поле Links National! И именно этот турнир войдет в историю!
          </p>
          <p>
            В чем же уникальность этого турнира? А вот в чем! Все знают, что Правила любительского
            статуса строго запрещают любителям играть за призовые деньги. Многие знают и то, что к
            некоторых странах - не будем их называть - порой вольно обходятся с этим запретом. (как
            тут не вспомнить «Если где-то кое-кто у нас порой…»). То слиток какой-нибудь драгоценный
            вручат, то членство в клубе подарят…, то машину посулят… В общем, слаб человек и
            соблазнам со страстями противостоит не очень!
          </p>
          <p>
            А тут турнир любительский, а призовой денежный фонд почти миллион рублей! Как такое
            может быть? Все просто – гольфисты-любители будут бороться за то, чтобы денежные призы
            получили их кэдди! Не пресловутые 10% от призовых, заработанных профи, а полноценные
            деньги, которые получат кэдди победителей (сами победители получат кубки, призы и
            подарки)! И деньги очень неплохие!
          </p>
          <p>
            За лучший счет без учета гандикапа, например, кэдди в мужском и женском зачетах
            заработают по 150 000 рублей! А в каждой из пяти гандикаповых групп (три у мужчин и две
            у женщин) призовой фонд для кэдди победителей и призеров составляет 110 000 рублей.
          </p>
          <p></p>
          <p></p>
          <p></p>
          <p></p>

          <p></p>
        </Segment>

        <Segment>
          <p>Более подробно о турнире в интервью с Максимом Шокуном, основателем CaddyGo:</p>
          <p>
            <a
              href='https://golf.ru/news/v_rossii/maksim_shokun_turnir_caddygo_novoe_slovo/'
              target='_blanc'
            >
              Golf.ru: «Турнир CaddyGo - это новое слово в турнирной жизни России»
            </a>
          </p>
        </Segment>

        <Segment>
          <p>Записаться на Турнир можно по адресу:</p>
          <p>
            <a href='https://tour.caddygo.ru' target='_blanc'>
              tour.caddygo.ru
            </a>
          </p>
        </Segment>
      </Segment.Group>
    </Container>
  );
};

export default TourCaddyGo;
